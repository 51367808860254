
const Utils = {
    getBriefText: (data) => {
        if (!data) {
            return '';
        }

        let textWithoutHTML = data.replace(/<[^>]*>?/gm, '3e3');
        textWithoutHTML = textWithoutHTML.replace(/3e3/gm, ''); // fix space between words
        // textWithoutHTML = textWithoutHTML.substring(0, 300) //brief text

        return `${textWithoutHTML}...`;
    },
    isEmptyObject: (obj) => {
        if (typeof obj === 'object') {
            return Object.keys(obj).length === 0;
        }
        return false;
    },
    isTestEnvironment: () => {
        const result = window &&
            window.location &&
            window.location.href &&
            (window.location.href.includes('test') ||
                window.location.href.includes('localhost'));

        return result;
    },
    createAndDownload: (body, filename, extension = 'pdf') => {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
};

export default Utils;
