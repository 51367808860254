import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ProposalCard from "./proposalCard";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    style={{
                        paddingTop: 18,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const StyledTabs = withStyles({
    root: {
        borderRadius: 5,
        backgroundColor: "#FFF",
        height: 64,
    },
})(Tabs);

const StyledTab = withStyles({
    root: {
        "&:first-child": {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
        },
        "&:last-child": {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
        },
        fontSize: 14,
        lineHeight: "24px",
        letterSpacing: 0.15,
        color: "#141B6A",
        height: 64,
        textTransform: "none",
    },
    selected: {
        backgroundColor: "#F2F2F2",
        fontWeight: 600,
        color: "#141B6A !important",
    },
})(Tab);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: "transparent",
    },
}));

export default function ProposalTabs({
    items,
    handleSelect,
    handleNext,
    selectedItem,
    quotation,
    optionsByQuote,
    insurance,
}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        handleSelect(newValue, items[newValue]);
        setValue(newValue);
    };

    /* React.useEffect(() => {
        if (selectedItem && !value) {
            setValue(selectedItem);
        }
        if (!value && !selectedItem) {
            setValue(0);
            handleSelect(0, items[0]);
        }
    }, [selectedItem]); */

    return (
        <div className={classes.root}>
            {items.length > 1 && (
                <AppBar position="static" color="default">
                    <StyledTabs
                        value={selectedItem || value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {items.map((product, index) => (
                            <StyledTab
                                label={product.formatedName}
                                {...a11yProps(index)}
                            />
                        ))}
                    </StyledTabs>
                </AppBar>
            )}
            {items.map((product, index) => (
                <TabPanel value={value} index={index}>
                    <ProposalCard
                        handleSelect={() => handleSelect(value, items[value])}
                        handleNext={handleNext}
                        product={product}
                        index={index}
                        isSelected={index === selectedItem}
                        products={items}
                        quotation={quotation}
                        optionsByQuote={optionsByQuote}
                        insurance={insurance}
                    />
                </TabPanel>
            ))}
        </div>
    );
}
