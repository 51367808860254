import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Carousel from '@brainhubeu/react-carousel';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import ModalCoverage from '../modal/modelCoverage';
import CustomCarousel from '../custom-carousel';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: 0,
        padding: 4
    },
    text: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    containerTitle: {
        display: 'flex'
    }
}));

const AmountCoverage = ({ coverages, index }) => {
    const classes = useStyles();

    const qtyItemsByScreenWidth = () => {
        const width = window.innerWidth;
        let qty = 3;
        if (width < 1000 && width > 780) {
            qty = 2;
        }
        if (width < 779) {
            qty = 1;
        }
        return qty;
    };
    const getRow = (coverage, i) => (
        <Grid container key={coverage._id}>
            <Grid item xs={12}>
                <div className={classes.containerTitle}>
                    <h4 className={classes.title}>{`${coverage.name} hasta:`}</h4>
                    <ModalCoverage coverage={coverage} />
                </div>
            </Grid>
            <CustomCarousel
                items={coverage.options.concat(coverage.options)}
                placeholder="Consultando coberturas"
                index={index}
                type={`info__${i}`}
            />
            {/* <Grid container justify="center" >
                <Carousel
                    arrows={false}
                    value={index}
                    slidesPerPage={qtyItemsByScreenWidth()}
                    offset={2}>
                    {coverage.options.map(option => (
                        <Grid item key={option._id}>
                            <p className={classes.text}>{option.limitImportCoverageMax ? `$${option.limitImportCoverageMax.toLocaleString('en-AR')}` : '-'}</p>
                        </Grid>
                    ))}
                </Carousel>
            </Grid> */}
        </Grid>
    );
    
    return (
        <Grid item xs={12} className="proposalConditionContainer">
            {coverages.length > 0 && coverages.map((coverage, i) => getRow(coverage, i))}
        </Grid>
    );
};

export default AmountCoverage;
