import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, InputLabel, makeStyles, Menu, MenuItem, withStyles } from '@material-ui/core';
import { Add, ArrowForwardIos, Close, Delete, Edit, ExpandMore, MoreVert } from '@material-ui/icons';
import moment from 'moment';
import Handlebars from 'handlebars';
import { useSelector } from 'react-redux';

const StyledAccordion = withStyles((theme) => ({
  root: {
    margin: '0px !important',
    boxShadow: 'none !important',
    '& .MuiAccordionDetails-root': {
      padding: '0px 16px'
    },
    backgroundColor: 'transparent',
    '&.is-wizard': {
      borderRadius: '0px !important',
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.primary.dark}`
    },
    '& .is-wizard': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark
    },
    '& .is-wizard-outlined': {
      borderColor: theme.palette.primary.dark
    }
  }
}))(Accordion);

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: 0.15,
    color: theme.palette.secondary.main,
    textAlign: 'left',
    flexDirection: 'row-reverse',
    margin: 0
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  expanded: {
    margin: 0
  },
  expandIcon: {
    color: theme.palette.secondary.main,
    margin: 0
  }
}))(AccordionSummary);

const StyledMenu = withStyles(() => ({
  list: {
    padding: 0
  }
}))(Menu);

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const CustomMenu = ({ id, handleDelete, isUpdating, handleUpdate, handleCancelUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton color="primary" onClick={handleClick}><MoreVert /></IconButton>
      <StyledMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(evt) => {
          evt.stopPropagation();
          handleDelete();
          handleClose()
        }}><Delete color="primary" /> Eliminar</MenuItem>
        {isUpdating === null ? <MenuItem onClick={(evt) => {
          evt.stopPropagation();
          handleUpdate();
          handleClose();
        }}><Edit color="primary" /> Editar</MenuItem> : <MenuItem onClick={(evt) => {
          evt.stopPropagation();
          handleCancelUpdate();
          handleClose();
        }}><Edit color="primary" /> Cancelar edicion</MenuItem>}
      </StyledMenu>
    </>
  );
};

const getLabelByType = (field) => {
  switch (field.dataType) {
    case 'string': {
      const fieldValue = field.remote && field.options.length > 0 ? field.options.find(opt => opt._id === field.value).name : field.value;
      return fieldValue;
    }
    case 'bool':
      return field.value ? field.options[0] : field.options[1];
    case 'numeric':
      return field.mask !== 'Moneda' ? field.value : `$${new Intl.NumberFormat("en-US").format(field.value.includes(',') ? field.value.replaceAll(',', '') : field.value)}`;
    case 'date':
    case 'birthdate':
      return field.controlType.includes('birthdate') ? moment().diff(field.value, 'years') : moment(field.value).format('DD/MM/YYYY');
    case 'object': {
      const options = JSON.parse(field.options[0]);
      const fieldValue = options.find(opt => opt.value == field.value)?.name;
      return fieldValue;
    }
    case 'array': {
      return field.value.reduce((str, curr, index) => str += `${curr.name}${index == field.value.length - 1 ? '' : ' | '}`, '');
    }
    default:
      console.log('Invalid type!');
      break;
  }
}

const getMappedFields = (fields) => {
  return fields.reduce((str, curr, i) => str += `${i > 0 && curr.value !== null ? ' - ' : ''}${curr.value !== null ? getLabelByType(curr) : ''}`, '');
}

const QuoteTitle = ({
  title,
  tags,
  handleCloseTag,
  show,
  multiple,
  handleFillExtraFields,
  fillingExtraFields,
  handleDeleteExtraField,
  max,
  groupIndex,
  stepIndex,
  wizard,
  readOnly,
  handleDeleteExtraFieldTag,
  itemTitle,
  groupTitle,
  groupPlaceholder
}) => {
  const config = useSelector(state => state.config.data);
  const [isUpdating, setIsUpdating] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (fillingExtraFields) {
      setIsUpdating(tags.length);
    } else {
      setIsUpdating(null);
    }
  }, [fillingExtraFields]);

  const handleUpdate = (outerIndex) => {
    setIsUpdating(outerIndex);
  };

  const handleCancelUpdate = () => {
    setIsUpdating(null);
  };

  const getFormat = (index, format) => {
    const fields = tags[index].concat({ name: 'count', dataType: 'string', value: Number(index) + 1 }).filter(f => f.value !== null).map(f => ({ [f.name]: getLabelByType(f) })).reduce((prev, curr) => curr = { ...curr, ...prev }, {});
    const template = Handlebars.compile(format.replaceAll('$', ''));
    const renderTemplate = template(fields);
    const _template = Handlebars.compile(renderTemplate);
    const rerenderTemplate = _template(fields);
    return rerenderTemplate;
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      {!fillingExtraFields && <h2 className="titleSection">{title}</h2>}
      {!multiple ? <>
        {!fillingExtraFields && <div className="tagsContent">
          {
            tags.map((tag, index) => {
              return (
                <Chip key={index} color="secondary" label={`${tag.chipLabel && tag.chipLabel !== '' ? tag.chipLabel : `${(wizard && tag.name === 'lead') ? '' : tag.name}`}${(wizard && tag.name === 'lead') ? '' : ': '}${getLabelByType(tag)}`} onDelete={() => handleCloseTag(tag, stepIndex)} />
              );
            })
          }
        </div>}
      </> : <>
        {groupTitle && <div className={classes.label}>
          <ArrowForwardIos color="primary" />
          <InputLabel id="external-input-label">{groupTitle}</InputLabel>
        </div>}
        {tags.map((tag, outerIndex) => {
          return (
            (!fillingExtraFields || (fillingExtraFields && outerIndex === tags.length - 1 && tag.some(t => t.value === null))) && <div className="itemContainer">
              <StyledAccordion defaultExpanded className={wizard ? 'is-wizard' : ''}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`item_panel_${groupIndex}_${outerIndex}`}
                  id={`item_panel_${groupIndex}_${outerIndex}`}>
                  {itemTitle ? <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: getFormat(outerIndex, itemTitle) }} /> : <span>{`Item ${outerIndex + 1}`}</span>}
                  {!readOnly && <CustomMenu
                    id={`item_menu_${groupIndex}_${outerIndex}`}
                    isUpdating={isUpdating}
                    handleUpdate={() => handleUpdate(outerIndex)}
                    handleCancelUpdate={() => handleCancelUpdate()}
                    handleDelete={() => {
                      handleCancelUpdate();
                      handleDeleteExtraField(outerIndex, groupIndex);
                    }} />}
                </StyledAccordionSummary>
                <AccordionDetails>
                  <div className="tagsContent">
                    {tag.filter(t => t.visible).map((t, innerIndex) => (
                      <div className="tagItem" style={{ marginTop: innerIndex > 2 ? 8 : 0 }}>
                        <span>{t.chipLabel && t.chipLabel !== '' ? t.chipLabel : t.name}</span>
                        {t.value ? (
                          <Chip
                            style={{ maxWidth: 98 }}
                            key={innerIndex}
                            color="secondary"
                            className={wizard ? 'is-wizard' : ''}
                            label={getLabelByType(t)}
                            onDelete={isUpdating !== null && isUpdating === outerIndex ? () => handleDeleteExtraFieldTag(outerIndex, groupIndex, innerIndex) : null}
                          />
                        ) : <Chip className={wizard ? 'is-wizard-outlined' : ''} variant="outlined" color="secondary" style={{ minWidth: 82 }} />}
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </StyledAccordion>
            </div>
          )
        })}
        {/* {tags.map((tag, outerIndex) => {
            return (
              <div className="multiTagContent" style={{ backgroundColor: config.theme.secondary.main, color: '#fff' }}>
                {!readOnly && <IconButton className="closeBtn" onClick={() => handleDeleteExtraField(outerIndex, groupIndex)}>
                  <Close />
                </IconButton>}
                {tag.filter((t) => t.value).map((t, innerIndex) => (
                  <div>
                    {innerIndex > 0 && <span>{t.chipLabel && t.chipLabel !== '' ? t.chipLabel : t.name}</span>}
                    <span>{getLabelByType(t)}</span>
                  </div>
                ))}
              </div>
            )
          })} */}
      </>}
      <h2 className="titleSection">{(((multiple && !fillingExtraFields && tags.length < max) || (multiple && !fillingExtraFields && max === 0)) && !readOnly) && <span className="item__add-btn" color="primary" onClick={handleFillExtraFields}><Add color="primary" /> {groupPlaceholder ? groupPlaceholder : 'Agregar'} <b>(Máximo: {max})</b></span>}</h2>
    </div>
  );
};

export default QuoteTitle;
