import React, { useMemo } from "react";
// import { FormConsumption } from "form-builder";
import { useParams } from "@reach/router";
import QuoteBottomButtons from "../quote/quoteBottomButtons";
import moment from "moment";

const getMaritalStatus = {
  "CASADO(A)": "Casado",
  "DIVORCIADO(A)": "Divorciado",
  "SOLTERO(A)": "Soltero",
};

// todo fill other options
const getDocumentType = {
  SLVIDCardV1: "DUI",
};

const FormPerson = (props) => {
  const urlParams = useParams();
  const document = props.document;

  const name =
    document && document.name
      ? document.name
      : props.quotationScreenValues.name;
//   const name =
//     props.quotationScreenValues && props.quotationScreenValues
//       ? props.quotationScreenValues.name
//       : document.name;


  const recognitionData = useMemo(
    () =>
      document
        ? {
            name,
            surname: document.surname,
            documentNumber: document.personalNumber
              ? document.personalNumber.replace("-", "")
              : "",
            birthday: moment(document.dateOfBirth, "DD/MM/YYYY", true).format(),
            address: document.address,
            email: document.email,
            maritalStatus: getMaritalStatus[document.maritalStatus],
            select1: getDocumentType[document.documentType],
            spouseName:
              document.spouseName &&
              document.spouseSurname &&
              document.spouseName !== "CORIDE" &&
              document.spouseSurname !== "WARE" &&
              document.spouseName !== "coride" &&
              document.spouseSurname !== "ware"
                ? `${document.spouseName} ${document.spouseSurname}`
                : "",
            dateOfIssuing: moment(
              document.dateOfIssuing,
              "DD/MM/YYYY",
              true
            ).format(),
            dateOfExpiry: moment(
              document.dateOfExpiry,
              "DD/MM/YYYY",
              true
            ).format(),
            cityOfBirth: document.cityOfBirth,
            nationality: document.nationality,
          }
        : {},
    [document]
  );

  return (
    <>
      {/*   {urlParams && (
          <FormConsumption
            onDataReturn={props.onDataReturn}
            id={urlParams.formBuilderId}
            loadData={recognitionData}
            paramToSave={urlParams.resultQuoteId}
            showTitle={false}
            redirect={props.goToNextPage}
          />
        )} */}

      <QuoteBottomButtons
        label="Siguiente"
        type="submit"
        disabled={props.pristine || props.submitting}
        showBtnNext={false}
      />
    </>
  );
};

FormPerson.defaultProps = {
  onDataReturn: () => console.log,
};

export default FormPerson;
