import React, { useEffect, useRef, useState } from 'react';
import { List, ListItem, ListSubheader, ListItemText, TextField, InputAdornment, Tooltip, IconButton, InputLabel } from '@material-ui/core';
import { useInputValue } from '../../../hooks/useInputValue';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, Input } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  listItem: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      }
    }
  },
  search: {
    marginBottom: '35px'
  },
  input: {
    marginTop: '2rem !important'
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 16,
    marginBottom: 18,
    '& svg': {
      marginRight: 6,
      fontSize: 16
    },
    color: theme.palette.typography.main,
    '& label': {
      overflow: 'unset',
      maxWidth: '100%',
      whiteSpace: 'break-spaces'
    }
  }
}));

const QuoteListObject = ({
  data,
  handleSelect,
  label,
  isOptional,
  isSearchable,
  quotationScreenOptions,
  handlePreSelect,
  preSelectedValue,
  handleNextButtonValid
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [labelHeight, setLabelHeight] = useState(16);
  const [inputLabel, setInputLabel] = useState('');
  const labelRef = useRef();
  const config = useSelector(state => state.config.data);
  const dataList = JSON.parse(data[0]);

  useEffect(() => {
    if (isOptional) {
      if (quotationScreenOptions.nextButtonType === 'ext') {
        handleNextButtonValid(true);
      }
    }
  }, []);

  useEffect(() => {
    if (labelRef.current) {
      setLabelHeight(labelRef.current.offsetHeight);
    }
  }, [labelRef.current]);

  useEffect(() => {
    setInputLabel(label);
  }, [label]);

  const handleChange = (item) => {
    quotationScreenOptions.nextButtonType === 'int' ? handleSelect(item.value) : handlePreSelect(item.value);
    setValue('');
    if (quotationScreenOptions.nextButtonType === 'ext') {
      handleNextButtonValid(true);
    }
  }

  const filterByValue = (item) => {
    return item.name.includes(value);
  };

  const handleChangeValue = (evt) => {
    setValue(evt.target.value);
  }

  return (
    <List
      component="nav"
      className="quoteList"
      subheader={
        <ListSubheader className={classes.search} component="h2">
          {(config.theme && !config.theme.labelPosition) && (
            <div className={classes.label}>
              <ArrowForwardIos color="primary" />
              <InputLabel id="external-input-label">{label}</InputLabel>
            </div>)}
          <TextField
            label={(config.theme && config.theme.labelPosition) ? label : null}
            fullWidth
            color="primary"
            value={value}
            onChange={handleChangeValue}
            InputProps={{
              endAdornment: (isOptional && quotationScreenOptions.nextButtonType === 'int') && (
                <InputAdornment position="end">
                  <Tooltip title={'Siguiente'} arrow open placement="top">
                    <IconButton
                      aria-label="check input value"
                      onClick={() => handleSelect('0')}
                      style={{
                        color: config.theme.primary.main
                      }}
                    >
                      <Input />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              readOnly: !isSearchable,
              disabled: !isSearchable,
              style: {
                marginTop: labelHeight
              },
              margin: 'dense'
            }}
          />
        </ListSubheader>
      }
    >
      {
        dataList.filter(filterByValue).map((item, index) => {
          return (
            <ListItem selected={item.value === preSelectedValue} className={classes.listItem} button key={index} onClick={() => handleChange(item)}>
              <ListItemText primary={item.name} />
            </ListItem>
          )
        })
      }
    </List>
  );
};

export default QuoteListObject;
