import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    Grid,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
} from "@material-ui/core";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/done.css";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { generalsIcons } from "../../../utils/icons";
import Logo from '../../../assets/images/iconnectance_isotipo.png';
import { useSelector } from "react-redux";
import settings from "../../../settings";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.primary.main,
        height: "calc(100vh - 57px)",
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        justifyContent: "center",
        textAlign: "center",
    },
    icon: {
        marginRight: 16,
    },
    iconContainer: {
        width: "100%",
    },
    title: {
        fontSize: 34,
        lineHeight: "36px",
        color: "#02183A",
        fontWeight: 400,
        marginBottom: 32,
    },
    description: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
        color: "#02183A",
        marginBottom: 28,
    },
    button: {
        width: "100%",
        padding: "19px 15px",
        maxWidth: 231,
    },
    box: {
        borderRadius: 20,
        backgroundColor: "white",
        padding: "78px 47px",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
        },
    },
    icon: {
        width: 73,
        height: 73,
        fill: "transparent",
        marginBottom: 26,
    },
}));

const CongratsComponentNotApproved = () => {
    const classes = useStyles();
    const steps = 7;
    const [insured, setInsured] = useState('');
    const [policy, setPolicy] = useState('');
    const config = useSelector(state => state.config.data);


    useEffect(() => {
        // clean all
        const policyNumber = sessionStorage.getItem('policy');
        const $insured = JSON.parse(sessionStorage.getItem('quotation::clientInfo'));
        const riskType = localStorage.getItem('quotation::risktype');
        const plan = JSON.parse(sessionStorage.getItem(`quotation::quote::${riskType}`));
        setPolicy({
            number: policyNumber,
            name: plan?.plan?.businessName
        });
        setInsured($insured?.name);
        return () => {
            localStorage.clear();
            sessionStorage.clear();
        };
    }, []);

    const goHome = () => {
        window.open('https://libraseguros.com.ar/', '_self');
    };

    return (
        <Container>
            <Stepper
                style={{ backgroundColor: "transparent" }}
                alternativeLabel
                activeStep={6}
                connector={<DotConnector />}
            >
                {Array(steps)
                    .fill("")
                    .map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={DotStepIcon} />
                        </Step>
                    ))}
            </Stepper>
            <Grid className={classes.box}>
                <SvgIcon className={classes.icon} viewBox="0 0 73 73">
                    {generalsIcons["congratulation"]}
                </SvgIcon>
                <Typography variant="h4" className={classes.title}>
                    ¡Hemos recibido tu información!
                </Typography>
                <Typography variant="h6" className={classes.contact}>
                    <p>
                        <img alt="Logo_IConnectance" src={config.loginLogoImage ? config.loginLogoImage : Logo} style={{ height: '40px' }} />
                    </p>
                    ¡A partir de ahora tenés tu póliza nro <b>{policy?.number}</b> dada de alta!
                    <br />
                    Podrás descargarla ingresando al portal <a href="https://uat-libra-auth.iconnectance.com/login/insured">({settings.authUrlBase}login/insured)</a>.
                    <br />
                    Cualquier duda contactate a <a href="mailto:clientes@libraseguros.com.ar">clientes@libraseguros.com.ar</a>
                </Typography>
                <Button
                    style={{ marginTop: 103 }}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={goHome}
                >
                    Entendido
                </Button>
            </Grid>
        </Container>
    );
};

export default CongratsComponentNotApproved;
