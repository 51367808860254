import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Step, StepLabel, Stepper } from "@material-ui/core";
import Swal from 'sweetalert2';
import moment from 'moment';
import { updateLead } from "../../../actions/leadActions";
import ClientInfoForm from '../../../components/form/layouts/clientInfoForm';
import FormPerson from "../../../components/form/formPerson";
import "../../../styles/form.css";
import { navigate, useParams } from "@reach/router";
import { GASTOS_MEDICOS } from "../../../utils/risksTypes";
import { SOLICITUD } from "../../../utils/scores";
import { toTitleCase } from "../../../utils";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import { useQuote } from "../../../hooks/useQuote";
import LayoutLoading from "../../../components/layout/layoutLoading";
import LayoutError from "../../../components/layout/layoutError";
import { saveClientRecognitionInfo } from "../../../actions/clientInfoActions";
import { getQuote } from "../../../actions/quoteActions";
import { putUpdateQuotationScreenValues } from "../../../actions/serviceActions";
import Utils from "../../../utils/utils";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { getQuotationAndCreateProposalAPI, isTecnoredRequired, quotationSiseTecnored, quotationTecnored, updateProposalToPolicyAPI, verifyUserStatus } from "../../../sevices/quoteService";
import { showMessage } from "../../../actions/notificationActions";
import LibraClientInfoForm from "../../../components/form/layouts/libraClientInfoForm";

const ClientInfoComponent = (props) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isStoredLink = !!urlParams.get("lead");
    const params = useParams();
    const riskType = props.riskType && props.riskType.toUpperCase();
    const dispatch = useDispatch();
    const [tecnoredRequired, setTecnoredRequired] = useState(false);
    const lead = useSelector((state) => state.lead.data);
    const quoteAllPlans = useSelector((state) => state.quoteAllPlans);
    const insurances = useSelector((state) => state.insurance);
    const { document } = useSelector(
        (state) => state.clientInfo.recognitionData
    );
    const quote = useQuote(riskType);
    const { quotation, plan } = quote;
    const { quotationScreenValues } = quotation;

    const leadMail =
        quotationScreenValues && quotationScreenValues.lead
            ? quotationScreenValues.lead
            : undefined;
    let hasBlackList = false;
    if (document) {
        document.email = leadMail;
    }

    useHandleLeadSource({
        isNew: false,
        status: SOLICITUD,
        trackingStrings: ["Ingreso datos cliente"],
        riskType,
    });

    useEffect(() => {
        if (quote.quotation && quote.quotation.lead) {
            handleTracking();
            handleTecnoredVerification();
        }
    }, []);

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            const quotationId = params.resultQuoteId;
            dispatch(getQuote(quotationId));
        }
    }, []);

    useEffect(() => {
        if (lead && lead.status === "Emitida") {
            navigate("/quote/completed");
        }
    }, []);

    useEffect(() => {
        if (
            quoteAllPlans &&
            quoteAllPlans.quotation &&
            quoteAllPlans.quotation.quotationScreenValues &&
            quoteAllPlans.quotation.quotationScreenValues.document
        ) {
            const documentInfo =
                quoteAllPlans.quotation.quotationScreenValues.document;
            dispatch(saveClientRecognitionInfo({ document: documentInfo }));
        }

        if (
            isStoredLink &&
            quoteAllPlans &&
            quoteAllPlans.quotation &&
            quoteAllPlans.quotation.quotationScreenValues &&
            quoteAllPlans.quotation.quotationScreenValues
                .isFullfilledClientInfoForm
        ) {
            const isFullfilledForm =
                quoteAllPlans.quotation.quotationScreenValues
                    .isFullfilledClientInfoForm;
            if (isFullfilledForm) {
                goToNextPage();
            }
        }
    }, [quoteAllPlans]);

    const handleTracking = async () => {
        const { _id, tracking } = lead;

        const leadBody = {
            tracking: `${tracking},[${new Date()
                .toTimeString()
                .slice(0, 5)}] - ${toTitleCase(
                    riskType
                )}: Ingreso datos cliente`,
            status: SOLICITUD,
            url: window.location.href,
            _id: _id,
        };
        await dispatch(updateLead(leadBody));
    };

    const handleTecnoredVerification = async () => {
        const $isTecnoredRequired = await isTecnoredRequired(riskType.toLowerCase().includes('auto') ? 4 : 24, plan?.idProduct);
        setTecnoredRequired($isTecnoredRequired);
    };

    const getDescription = (key, value) => {
        const quotation = JSON.parse(sessionStorage.getItem(`quotation::${riskType.toUpperCase()}`));
        if (quotation) {
            const field = quotation.groups[0].fields.find(f => f.name === key);

            if (field) {
                const option = field.options.find(opt => opt._id == value);
                if (option)
                    return option.name;
            }
        }

        return value;
    };

    const goToNextPage = () => {
        if (hasBlackList) return;


        if (!tecnoredRequired) {
            navigate('inspection');
        } else {
            Swal.fire({
                title: '',
                html: `Recibimos tu propuesta de póliza, te estaremos enviando un link 
                a tu mail para que realices la inspección de tu vehículo, y completar 
                las tareas para emitir tu poliza.`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: '#f96114',
                customClass: {
                    container: 'modal__message',
                    loader: 'modal__message-loader'
                },
                didOpen: () => {
                    navigate('/transition');
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.open('https://libraseguros.com.ar/', '_self');
                };
            });
        }
    };

    const handleDataReturn = async (data) => {

        const quotationId = params.resultQuoteId;
        const quoteBody = {
            quotationId: quotationId,
            proposalData: {
                id_pv_wkf: plan._id,
                Ramo: riskType.toLowerCase().includes('auto') ? 4 : 24,
                propuesta_riesgo: [
                    {
                        codItem: 1,
                        escerokm: quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.kilometraje,
                        codigocombustible: 1,
                        codigocarroceria: riskType.toLowerCase().includes('auto') ? 2 : 1,
                        numerochasis: data.vehicleChassisNumber,
                        numeromotor: data.vehicleEngineNumber,
                        patente: data.patent,
                        color: data.vehicleColor,
                        icn_color: data.vehicleColorDesc
                    }
                ],
                insured: {
                    is_payer: 1,
                    apellido1: data.lastname,
                    apellido2: null,
                    nombre: data.name,
                    codtipodoc: 1,
                    nrodoc: data.documentNumber,
                    nronit: null,
                    fecnac: data.birthday,
                    txtsexo: data.gender,
                    email: data.email,
                    telephonePrefix: "",
                    telefono: data.phone,
                    nro_cod_postal: quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.cp,
                    cod_pais: 1,
                    nro_apto: null,
                    txt_direccion: data.street,
                    sp_nro_cta_tarj: data.creditCardNumber.replace(/\s/g, ""),
                    sp_aaaamm_vto_tarj: data.creditCardExpiryDate.split(' / ').reverse().map((f, i) => { if (i === 0) return `20${f}`; return f; }).join(''),
                    tipo_persona: "F",
                    sp_cod_conducto: data.cardType,
                    contactType: 1,
                    cod_tipo_dir: 1,
                    cod_condicion_imp: 1,
                    cod_dpto: quotationScreenValues?.quoteData?.Provincia,
                    cod_municipio: quotationScreenValues?.quoteData?.Municipio,
                    cod_colonia: 1,
                    sp_ind_conducto: 0,
                    sp_imp_limite_tarj: 0,
                    sp_cod_banco_emisor_tarj: 0,
                    sp_sn_habilitado: 0,
                    nro_nro2: 0
                }
            },
            status: null
        };
        await getQuotationAndCreateProposalAPI(quoteBody);
        /* if (tecnoredRequired) {
            const tecnoredBody = {
                apellido: " ", //Apellido del tomador si lo ingresa por pantalla
                nombre: `${data.name} ${data.lastname}`, //Nombre del tomador
                usuario: 'libra online', //Aquí tengo el nombre del agente, asumo que en el directo sera "libra online"
                dominio: data.patent, //La placa del carro
                marca: getDescription('Marca', quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.Marca), //Aqui va la marca textualmente
                modelo: getDescription('Modelo', quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.Modelo), //MOdelo de forma textual
                anio: parseInt(
                    quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.AnioVehiculo
                ), //El año del carro
                codigoCompania: plan._id, //Este es el id de la solicitud el que mandas en id_pv_wkf al actualizar
                organizador: 'libra online', //Lo mismo que en el usuario
                gestionadorCuit: "12345", //Aqui no sabia que poner asi que puse eso
                email: data.email, //Mail del tomador
                prodEmail: 'cotizaciones@libraseguros.com.ar', //Mail del productor, como usuamos libra online deberia ser algo como libraseguros@blablabla.com (consultale a yuli mejor este)
                gestionadorEmail: `${data.email};cotizaciones@libraseguros.com.ar`, //los dos mails en el orden que ves
                cp: parseInt(quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.cp), //el codigo postal con el que cotizaron
                localidad: quotationScreenValues?.quoteData?.Calle, //Aqui va la localidad en caso de que sea diferente a caba si es caba pasé la calle y el numero
                provincia: quotationScreenValues?.quoteData?.DescProvincia, //Textual de la provincia
                tipoVehiculo: riskType.toLowerCase().includes('auto') ? 1 : 22, //hardcode no se que es pero me lo pide
                audita: 1, //hardcode no se que es pero me lo pide
                codInfoauto: 0, //hardcode no se que es pero me lo pide
                tipo: 1, //hardcode no se que es pero me lo pide
                canal: 3, //hardcode no se que es pero me lo pide
            };
            const tecnoredResult = await quotationTecnored(tecnoredBody);
            const siseTecnoredBody = {
                id_pv: plan._id, //id_pv_wkf o el id de la solicitud que comente
                cod_ramo: riskType.toLowerCase().includes('auto') ? 4 : 24, //codigo de ramo
                cod_agente: 4378, //aquí no se que se enviaria desde libra online, asumo que 0-0-0-0 o 0
                id_tecno: tecnoredResult.id, //Tecnored cuando responde te arroja un id este se lo ponemos aca
                cod_aseg:
                    result.externalProposal
                        .DetallesPlanDePago[0].Cod_aseg, //Cuando actualizamos la solicitud con getQuotationAndUpdate... te da un codigo de asegurado, hay que pasarselo aqui
                fecha_emision: moment(new Date()).format("YYYY-MM-DD"), //esto no es mas que la fecha actual en el formato que nos pide sise
            };
            await quotationSiseTecnored(siseTecnoredBody);
        } */

        dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: {
                    isFullfilledClientInfoForm: !!data,
                    clientInfo: data
                },
                _id: quotationId,
            })
        );
    };

    const checkLibraPlan = () => {
        const insurance = insurances.data.find(
            (insurance) => insurance._id === quote.quotation.insuranceId
        );

        return insurance?.businessName?.toLowerCase().includes('libra');
    };

    const steps = 7;
    return (
        <>
            {quote.loading && <LayoutLoading />}
            {quote.error && <LayoutError />}
            {!quote.loading && !insurances.loading && quote.quotation && quote.quotation.products && (
                <>
                    <Container>
                        <Stepper
                            style={{ backgroundColor: "transparent" }}
                            alternativeLabel
                            activeStep={1}
                            color="primary"
                            connector={<DotConnector />}
                        >
                            {/* <QuoteProgress width="25%" /> */}
                            {Array(steps)
                                .fill("")
                                .map((label) => (
                                    <Step key={label} color="primary">
                                        <StepLabel
                                            StepIconComponent={DotStepIcon}
                                        />
                                    </Step>
                                ))}
                        </Stepper>
                        {riskType === GASTOS_MEDICOS ? (
                            <div className="cardAppContainerFormBuilder">
                                {document ? (
                                    <>
                                        <LibraClientInfoForm
                                            plan={plan}
                                            onDataReturn={handleDataReturn}
                                            goToNextPage={goToNextPage}
                                            riskType={riskType}
                                            quotationScreenValues={
                                                quotationScreenValues
                                            } />
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            <>
                                <LibraClientInfoForm
                                    plan={plan}
                                    onDataReturn={handleDataReturn}
                                    goToNextPage={goToNextPage}
                                    riskType={riskType}
                                    quotationScreenValues={
                                        quotationScreenValues
                                    } />
                            </>
                        )}
                    </Container>
                </>
            )}
        </>
    );
};

export default ClientInfoComponent;
