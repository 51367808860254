import React, { useEffect} from "react";
import { Grid } from "@material-ui/core";

function SuccessPaymentLink({ external_reference, paymentId }) {
    return ( 
        <Grid item xs={12}>
            <Grid style={{ textAlign: "center" }} >
                <h1 style={{ color: "green" }} >PAGO EXITOSO</h1>
                <h3 style={{ color: "grey", textDecoration: "underline" }} ><strong>ID de pago:</strong></h3> <code>{paymentId} </code>
                <h3 style={{ color: "grey", textDecoration: "underline" }} ><strong>Numero de referencia:</strong></h3> <code>{external_reference}</code>
            </Grid>
        </Grid>
    )
}

export default SuccessPaymentLink;