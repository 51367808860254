import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

const useArrowStepStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    '&.first': {
      borderTopColor: `${theme.palette.primary.main} !important`,
      borderBottomColor: `${theme.palette.primary.main} !important`,
      borderRightColor: `${theme.palette.primary.main} !important`,
      '&::before': {
        borderTopColor: `${theme.palette.primary.main} !important`,
        borderBottomColor: `${theme.palette.primary.main} !important`,
        borderLeftColor: `${theme.palette.primary.main} !important`,
      },
      '&::after': {
        borderLeftColor: `${theme.palette.primary.main} !important`,
      }
    },
    borderTopColor: `${theme.palette.primary.main} !important`,
    borderBottomColor: `${theme.palette.primary.main} !important`,
    borderRightColor: `${theme.palette.primary.main} !important`,
    '&::after': {
      borderLeftColor: `${theme.palette.primary.main} !important`,
    }
  },
  completed: {
    '&.first': {
      borderTopColor: `${theme.palette.secondary.main} !important`,
      borderBottomColor: `${theme.palette.secondary.main} !important`,
      borderRightColor: `${theme.palette.secondary.main} !important`,
      '&::before': {
        borderTopColor: `${theme.palette.secondary.main} !important`,
        borderBottomColor: `${theme.palette.secondary.main} !important`,
        borderLeftColor: `${theme.palette.secondary.main} !important`,
      },
      '&::after': {
        borderLeftColor: `${theme.palette.secondary.main} !important`,
      }
    },
    borderTopColor: `${theme.palette.secondary.main} !important`,
    borderBottomColor: `${theme.palette.secondary.main} !important`,
    borderRightColor: `${theme.palette.secondary.main} !important`,
    '&::after': {
      borderLeftColor: `${theme.palette.secondary.main} !important`,
    }
  },
  stepLabelContainer: {
    height: 56,
    position: 'absolute',
    zIndex: 1,
    width: '100%'
  },
  arrowStep: {
    height: 0,
    borderRadius: 5,
    position: 'relative',
    borderTop: `28px solid ${theme.palette.secondary.light}`,
    borderRight: `25px solid ${theme.palette.secondary.light}`,
    borderBottom: `28px solid ${theme.palette.secondary.light}`,
    borderLeft: '25px solid transparent',
    '&.first::before': {
      borderTop: `28px solid ${theme.palette.secondary.light}`, // Esto va transparent en los que no son el primero
      borderBottom: `28px solid ${theme.palette.secondary.light}`, // Esto va transparent en los que no son el primero
      borderLeft: `25px solid ${theme.palette.secondary.light}`,
    },
    '&::before': {
      content: '""',
      display: 'block',
      borderRadius: 5,
      borderTop: '28px solid transparent', // Esto va transparent en los que no son el primero
      borderRight: 0,
      borderBottom: '28px solid transparent', // Esto va transparent en los que no son el primero
      borderLeft: '25px solid transparent',
      position: 'relative',
      bottom: 28,
      left: -30
    },
    '&::after': {
      content: '""',
      borderRadius: 5,
      display: 'inline-block',
      borderTop: '28px solid transparent',
      borderRight: 0,
      borderBottom: '28px solid transparent',
      borderLeft: `25px solid ${theme.palette.secondary.light}`,
      position: 'absolute',
      top: -28,
      right: -48
    }
  }
}));

function ArrowStepIcon(props) {
  const classes = useArrowStepStyles();
  const { active, icon, completed } = props;
  return (
    <div className={classes.root}>
      <div className={classes.stepLabelContainer}>
        <div className={clsx(classes.arrowStep, {
          'first': icon === 1,
          [classes.active]: active,
          [classes.completed]: completed
        })} />
      </div>
    </div>
  );
};

const ArrowStep = withStyles({
  root: {
    marginRight: 8,
    padding: 0
  }
})(Step);

const ArrowStepLabel = withStyles({
  root: {
    position: 'relative',
    textAlign: 'center',
    color: '#fff'
  },
  iconContainer: {
    padding: 0
  },
  labelContainer: {
    zIndex: 2,
    paddingLeft: 16
  },
  active: {
    color: '#fff !important'
  },
  completed: {
    color: '#fff !important'
  },
  label: {
    color: '#fff'
  }
})(StepLabel);

const CustomWizard = ({ activeStep, steps, style }) => {
  return (
    <Stepper activeStep={activeStep} connector={null}>
      {steps.map((label, i) => (
        <ArrowStep key={label} style={style}>
          <ArrowStepLabel StepIconComponent={ArrowStepIcon}>{`${i + 1}. ${label}`}</ArrowStepLabel>
        </ArrowStep>
      ))}
    </Stepper>
  )
}

export default CustomWizard