import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Typography } from '@material-ui/core';
import { ReactComponent as Schedule } from '../../assets/images/modal/contact_us.svg';
import { ReactComponent as PhoneICon } from '../../assets/images/icons/phoneIcon.svg';
import { ReactComponent as WsIcon } from '../../assets/images/icons/wsIcon.svg';
import { ReactComponent as MailIcon } from '../../assets/images/icons/mailIcon.svg';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  cancel: {
    display: 'grid',
    justifyContent: 'end'
  },
  cancelButton: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    height: 24,
    width: 24
  },
  icon: {
    marginRight: 16
  },
  iconContainer: {
    padding: 32
  },
  image: {
    margin: '16px 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    textAlign: 'center'
  }
}));

const ModalSchedule = ({ open, handleSchedule }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="schedule"
      aria-describedby="schedule-contact-us"
      className={classes.modal}
      open={open}
      onClose={handleSchedule}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.cancel}>
            <div className={classes.cancelButton} onClick={handleSchedule}>
              <ClearIcon />
            </div>
          </div>
          <Typography className={classes.title}>
            <strong>Contactanos</strong>
          </Typography>
          <Schedule className={classes.image} />
          <Typography>
            Lunes a viernes de <strong>8 a 17 horas.</strong>
          </Typography>
          <div className={classes.iconContainer}>
            <a href="tel:5491126499551">
              <PhoneICon className={classes.icon} />
            </a>
            <a href="https://wa.me/5491126499551" target="_blank" rel="noreferrer noopener">
              <WsIcon className={classes.icon} />
            </a>
            <a href="mailto:solicitar@seguros.com?subject=Solicitud">
              <MailIcon className={classes.icon} />
            </a>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalSchedule;
