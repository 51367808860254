import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const getPaymentMethodsAPI = createAsyncAction('GET_PAYMENT_METHODS', async () => {
    const headers = {
        'Content-Type': 'application/json'
    };

    const res = await fetch(`${settings.quotationUrlBase}paymentMethodsPublic`, { headers })
        .then((response) => settings.validateResponse(response))
        .catch((error) => {
            throw error;
        });
    return res;
});
