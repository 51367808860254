import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
const useStyle = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        backgroundColor: theme.palette.variants.variant1,
    },
    cardContent: {
        paddingRight: 26,
        paddingLeft: 27,
        "&:last-child": {
            paddingBottom: 40,
        },
    },
    cardHeader: {
        textAlign: "center",
        color: "#FFF",
        paddingTop: 31,
        paddingBottom: 30,
        fontSize: 36,
        lineHeight: "24px",
        letterSpacing: 0.15,
        fontWeight: 300,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            lineHeight: "40px",
            fontSize: 24,
        },
    },
    cardTitle: {
        fontWeight: 500,
    },
    table: {
        color: "#F86115",
        "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            borderBottom: "none",
        },
        "& .MuiTableCell-root, .MuiTableRow-root:first-child .MuiTableCell-root":
        {
            borderBottom: "1px solid #CADDE9",
        },
        "& .MuiTableRow-head .MuiTableCell-head": {
            color: "#F86115",
            fontSize: 20,
            lineHeight: "24px",
            fontWeight: 500,
            letterSpacing: 0.15,
            borderBottom: "none !important",
        },
    },
    cellBody: {
        color: '#415364'
    }
}));

const CardSummary = ({ plan, quotationScreenValues, riskType, insurance }) => {
    const classes = useStyle();
    const name = plan && plan.formatedName ? plan.formatedName : ""
    const price = plan && plan.price ? plan.price : 1

    const getDescription = (key, value) => {
        const quotation = JSON.parse(sessionStorage.getItem(`quotation::${riskType.toUpperCase()}`));
        if (quotation) {
            const field = quotation.groups[0].fields.find(f => f.name === key);

            if (field) {
                const option = field.options.find(opt => opt._id == value);
                if (option)
                    return option.name;
            }
        }

        return value;
    };

    return (
        <Card
            elevation={0}
            classes={{
                root: classes.card,
            }}
        >
            <CardHeader
                classes={{
                    root: classes.cardHeader,
                }}
                title={
                    <Typography variant={"inherit"}>
                        Plan{" "}
                        <span className={classes.cardTitle}>{name}</span>
                    </Typography>
                }
            />

            <CardContent classes={{ root: classes.cardContent }}>
                <Table classes={{ root: classes.table }}>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Importe
                            </TableCell>
                            <TableCell align="right">
                                <strong>{`$${new Intl.NumberFormat("es-AR", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                }).format(price)}/mes`}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table classes={{ root: classes.table }}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>Vehículo asegurado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell classes={{ body: classes.cellBody }} component="th" scope="row">
                                Patente
                            </TableCell>
                            <TableCell classes={{ body: classes.cellBody }} align="right">
                                <strong>{quotationScreenValues?.clientInfo?.patent}</strong>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell classes={{ body: classes.cellBody }} component="th" scope="row">
                                Marca
                            </TableCell>
                            <TableCell classes={{ body: classes.cellBody }} align="right">
                                <strong>{getDescription('Marca', quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.Marca)}</strong>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell classes={{ body: classes.cellBody }} component="th" scope="row">
                                Modelo
                            </TableCell>
                            <TableCell classes={{ body: classes.cellBody }} align="right">
                                <strong>{getDescription('Modelo', quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.Modelo)}</strong>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell classes={{ body: classes.cellBody }} component="th" scope="row">
                                Año
                            </TableCell>
                            <TableCell classes={{ body: classes.cellBody }} align="right">
                                <strong>{quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.AnioVehiculo}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default CardSummary;
