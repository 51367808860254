import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const getSinglePaymentLinkAPI = createAsyncAction(
    'GET_SINGLE_PAYMENT_LINK',
    async (Id) => {
        const headers = {
            'Content-Type': 'application/json',
        };
        
        const res = await fetch(`${settings.agentUrlPublicApi}paymentLinkPublic/${Id}`, {
            headers,
        })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });

        return res;
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = "error") => {
    return error;
};