import React, { useEffect } from 'react'

const TransitionComponent = () => {
  useEffect(() => {
    return () => {
      localStorage.clear();
      sessionStorage.clear();
    };
  }, []);
  return (
    <div></div>
  )
}

export default TransitionComponent