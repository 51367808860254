import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PhoneICon } from '../../../assets/images/icon_phone_white.svg';
import { ReactComponent as WsIcon } from '../../../assets/images/icon_whatsapp_white.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/icon_mail_white.svg';
import QuoteTitleHeader from '../../../components/quote/quoteTitleHeader';
import '../../../styles/done.css';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: theme.palette.primary.main,
        height: 'calc(100vh - 57px)'
    },
    container: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        justifyContent: 'center',
        textAlign: 'center'
    },
    icon: {
        marginRight: 16
    },
    iconContainer: {
        width: '100%'
    }
}));

const FirmApprovedComponent = () => {
    const classes = useStyles();
    return (
        <Grid container justify="center" alignItems="center" className="page">
            <QuoteTitleHeader to="/" title="Inicio" />
            <Grid container justify="center" className={`appContainer ${classes.background}`}>
                <Grid item xs={12} sm={11} md={10} lg={8} className={classes.container}>
                    <Grid container>
                        <div className="header-done">
                            <img className="img__done" alt="Contratulations" />
                        </div>
                        <h2 className="done__container--title">Felicitaciones!!!</h2>
                        <h3 className="done__container--subtitle">TU PÓLIZA HA SIDO GENERADA EXITOSAMENTE</h3>
                        <p className="done__container--description">Te puedes contactar con nosotros para cualquier consulta de Lunes a Viernes de 8 a 19 hs a:</p>
                        <div className={classes.iconContainer}>
                            <a href="tel:5491126499551"><PhoneICon className={classes.icon} /></a>
                            <a href="https://wa.me/5491126499551" target="_blnak"><WsIcon className={classes.icon} /></a>
                            <a href="mailto:solicitar@seguros.com?subject=Solicitud"><MailIcon className={classes.icon} /></a>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FirmApprovedComponent;
