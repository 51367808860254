import { getYearsAPI, getBrandsAPI, getModelsAPI, getVersionsAPI, getAccesoriesAPI, postSaveQuotationAPI, putUpdateQuotationAPI, putUpdateQuotationScreenValuesAPI, getQuotationAndCreatePolicyRequestAPI, getValueIMC }
    from '../sevices/quoteService';
import { REMOVE_ALL_QUOTE } from './types';

export const getYears = () => (dispatch) => {
    dispatch(getYearsAPI());
};

export const getBrands = (year) => (dispatch) => {
    dispatch(getBrandsAPI(year));
};

export const getModels = (brand, year) => (dispatch) => {
    dispatch(getModelsAPI(brand, year));
};

export const getVersions = (model, year) => (dispatch) => {
    dispatch(getVersionsAPI(model, year));
};

export const getAccesories = () => (dispatch) => {
    dispatch(getAccesoriesAPI());
};

export const postSaveQuotation = (quote, riskType) => async (dispatch) => {
    dispatch({ type: REMOVE_ALL_QUOTE });
    const data = await postSaveQuotationAPI(quote, riskType);
    dispatch(data);
    return await data.payload;
};

export const getQuotationAndCreatePolicyRequest =
    (quotationId, idProduct) => (dispatch) => {
        dispatch(getQuotationAndCreatePolicyRequestAPI(quotationId, idProduct));
    };

export const putUpdateQuotation = (quote) => (dispatch) => {
    dispatch(putUpdateQuotationAPI(quote));
};

export const putUpdateQuotationScreenValues = (quote) => (dispatch) => {
    dispatch(putUpdateQuotationScreenValuesAPI(quote));
};

export const getIMC = async (weight, height) => {
    const medicalInformation = { weight, height }; 
    return await getValueIMC(medicalInformation);
}
