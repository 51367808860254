import { navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    makeStyles,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@material-ui/core";
import moment from "moment";
import { getInsuranceById } from "../../../actions/insuranceActions";
import { updateLead } from "../../../actions/leadActions";
import { getPaymentMethods } from "../../../actions/paymentMethodsActions";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import { useQuote } from "../../../hooks/useQuote";
import { PENDIENTE_DE_PAGO, PAGADA } from "../../../utils/scores";
import { toTitleCase } from "../../../utils";
import { getClientInfo } from "../../../actions/clientInfoActions";
import { getQuote, patchPlan } from "../../../actions/quoteActions";
import Utils from "../../../utils/utils";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import CardSummary from "../../../components/sharing/cardSummary";
import settings from "../../../settings";
// import { showMessage } from "../../../actions/notificationActions";

const useStyle = makeStyles((theme) => ({
    box: {
        borderRadius: 20,
        backgroundColor: "white",
        padding: "78px 47px",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
        },
    },
    stepper: {
        backgroundColor: "#f4f4fa",
        [theme.breakpoints.up("md")]: {
            maxWidth: 1100,
        },
        margin: "0 auto",
        width: "90%",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
            padding: 12,
        },
    },
    button: {
        marginTop: 52,
        width: "100%",
        padding: "19px 15px",
        maxWidth: 231,
    },
    title: {
        fontSize: 34,
        lineHeight: "36px",
        color: "#02183A",
        fontWeight: 400,
        marginBottom: 32,
    },
    description: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
        color: "#02183A",
        marginBottom: 28,
    },
    card: {
        borderRadius: 15,
        backgroundColor: theme.palette.variants.variant1,
    },
    cardContent: {
        padding: "22px 15px 32px 28px",
        color: '#415364'
    },
    loaderContainer: {
        marginTop: 52,
        paddingLeft: 25
    }
}));

const PaymentComponent = ({ riskType }) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [, setErrorMes] = useState(false);
    const [isPlanSet, setIsPlanSet] = useState(false);
    const [, setOpenPaymentMethods] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const insurances = useSelector((state) => state.insurance);
    const payment = useSelector((state) => state.payment);
    const lead = useSelector((state) => state.lead.data);
    const riskType_ =
        riskType.toUpperCase() || localStorage.getItem("quotation::risktype");
    const quote = useQuote(riskType_);
    const { plan, quotation } = quote;
    const { quotationScreenValues } = quotation;
    const clientInfo =
        quotationScreenValues && quotationScreenValues.clientInfo
            ? quotationScreenValues.clientInfo
            : {};

    const existPaymentData =
        !Utils.isEmptyObject(paymentDetails) &&
        paymentDetails.amount &&
        parseInt(paymentDetails.amount) > 0;

    const startVigency = moment().add(1, "day");
    const isAlreadyPayed =
        quotationScreenValues && quotationScreenValues.isAlreadyPayed
            ? quotationScreenValues.isAlreadyPayed
            : undefined;

    const paymentMethods = useSelector((state) => state.paymentMethods.data);

    const params = useParams();

    const _insuranceId = params && params.insuranceId ? params.insuranceId : "";

    const insurances_ = insurances.data.length
        ? insurances.data.find((insurance) => insurance._id === _insuranceId)
        : [];

    const isManualPayment = insurances_ ? insurances_?.options?.manualPayment : false;

    const paymentMethodsIds =
        insurances_ &&
            insurances_.options &&
            insurances_.options.paymentMethods &&
            insurances_.options.paymentMethods.length
            ? insurances_.options.paymentMethods
            : [];

    const getPaymentMethods_ = () => {
        if (
            (!insurances.loading && !paymentMethodsIds) ||
            !paymentMethodsIds.length
        ) {
            return [];
        }

        const paymentsMethodsDefinitions = paymentMethodsIds.map((id) =>
            paymentMethods.find((paymentMethod) => paymentMethod._id === id)
        );

        // remove undefined values
        return (
            !insurances.loading &&
            paymentsMethodsDefinitions.filter((item) => item)
        );
    };

    useEffect(() => {
        setIsLoading(true);
        initInsurance();
        initPaymentMethods();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            getQuoteInfo();
        }
    }, []);

    useEffect(() => {
        if (!!params && params.resultQuoteId) {
            localStorage.setItem("quotation::id", params.resultQuoteId);
        }
    }, [params]);

    useEffect(() => {
        if (lead && lead.status === "Emitida") {
            navigate("/quote/completed");
        }
    }, []);

    useEffect(() => {
        if (
            (quotation.productSelected && !isPlanSet) ||
            Utils.isEmptyObject(plan)
        ) {
            recoverPlanInfo();
        }
    }, [plan, quote]);

    const getQuoteInfo = async () => {
        const quotationId = params.resultQuoteId;
        dispatch(getQuote(quotationId));
    };

    const recoverPlanInfo = async () => {
        if (quote.quotation.products && quote.quotation.productSelected) {
            let reconveredPlan = quote.quotation.products.find(
                (item) => item._id === quote.quotation.productSelected
            );

            //todo: Fix, falla de logica, preguntar por esta logica: buscar por _id o por productId?
            if (!reconveredPlan) {
                reconveredPlan = quote.quotation.products.find(
                    (item) => item.productId === quote.quotation.productSelected
                );
            }

            dispatch(patchPlan(reconveredPlan));
            setIsPlanSet(true);
        }
    };

    const initInsurance = () => {
        if (params && params.insuranceId) {
            dispatch(getInsuranceById(params.insuranceId));
        }
    };

    const initPaymentMethods = () => {
        if (!paymentMethods.length) {
            dispatch(getPaymentMethods());
        }
    };

    useHandleLeadSource({
        isNew: false,
        status: PENDIENTE_DE_PAGO,
        trackingStrings: ["En proceso de pago"],
        riskType: riskType.toUpperCase(),
    });

    /* const execGetClientInfo = async () => {
        await dispatch(getClientInfo(params.resultQuoteId));
    }; */

    useEffect(() => {
        const existClientInfo = Object.keys(clientInfo).length > 0;
        const existPlan = !Utils.isEmptyObject(plan);

        if (existClientInfo && existPlan) {
            setPaymentData();
        }
    }, [clientInfo, plan]);

    const setPaymentData = async () => {
        sessionStorage.setItem(
            "quotation::clientInfo",
            JSON.stringify(clientInfo)
        );
        // TODO: IMPORTANTE: propiedades de clientInfoData pueden cambiar de nombre si cambian en el form dinamico
        const price = plan && plan.price ? plan.price : 0;
        const name = plan && plan.name ? plan.name : "";
        const productId = plan && plan.productId ? plan.productId : "";

        const amount = price; // this is hardcode (/12) for payment of only 1 monthly installment

        const data = {
            description: `${name} (pago de la primera cuota)`,
            amount,
            external_reference: JSON.stringify({
                productId: productId,
                quotationId: quotation._id,
            }),
            email: quotation.lead,
            userFirstName: clientInfo.name,
            userLastName: clientInfo.surname,
        };

        try {
            // the payment is manual
            if (!isManualPayment) {
                setOpenPaymentMethods(true);
                setPaymentDetails(data);
                return;
            }
        } catch (error) {
            setErrorMes(true);
        }
    };

    const updateTrackingCallback = async (trackingData) => {
        const { _id, tracking } = lead;
        const leadBody = {
            ...lead,
            tracking: `${tracking}, ${trackingData}`,
            status: PENDIENTE_DE_PAGO,
            url: window.location.href,
            _id: _id,
        };

        await dispatch(updateLead(leadBody));
    };

    const paymentCallback = (
        paymentMethodName,
        paymentDetails,
        redirectURL
    ) => {
        const trackingData = `${new Date()
            .toTimeString()
            .slice(0, 5)}] - ${toTitleCase(
                riskType
            )}: En proceso de pago a través de ${paymentMethodName}`;

        updateTrackingCallback(trackingData);

        // setTriggerPaymentPendingLead(true);
        setTimeout(() => {
            setIsLoading(false);
            navigate(redirectURL);
        }, 1000);
    };

    const handleNotDefinePaymentMethod = () => {
        setOpenPaymentMethods(false);
        navigate("/");
    };

    const paymentListComponent = () =>
        !insurances.loading && getPaymentMethods_().length ? (
            getPaymentMethods_().map((item) => (
                <Button
                    className={classes.button}
                    disabled={!existPaymentData}
                    variant="contained"
                    color="primary"
                    key={item}
                    onClick={() => {
                        /*  if(!plan){
                            setIsPlanSet(false)
                        }
                         */
                        setOpenPaymentMethods(false);

                        // get sendPayment method (all payment method should contain this method)
                        const method = item.methods.find(
                            (method) => method.methodName === "sendPayment"
                        );

                        paymentDetails.brokerName =
                            settings.brokerName || "IConnectance";
                        // transform the string code to functional code
                        eval(method.code)(paymentDetails, paymentCallback);
                    }}
                >
                    Siguiente
                </Button>
            ))
        ) : (
            <div style={{ textAlign: "center" }}>
                {!insurances.loading && (
                    <>
                        <p>
                            No hay metodo de pago definido por favor contacte al
                            administrador
                        </p>
                        <Button
                            key={"not_define_PM"}
                            onClick={() => {
                                handleNotDefinePaymentMethod();
                            }}
                        >
                            cerrar
                        </Button>
                    </>
                )}
            </div>
        );

    const isAlreadyPayedComponent = () => (
        <div style={{ textAlign: "center", width: "100%" }}>
            <p>Esta cotización ya fue pagada</p>
            <Button
                key={"not_define_PM_Already_payed"}
                onClick={() => navigate("client-vinculation")}
            >
                aceptar
            </Button>
        </div>
    );

    const steps = 7;
    const nameInfo = {
        GASTOS_MEDICOS: clientInfo.name,
        FIANZA: clientInfo.input2,
        AUTOS: clientInfo.name
    };

    return (
        <Container>
            <Stepper
                className={classes.stepper}
                alternativeLabel
                activeStep={3}
                connector={<DotConnector />}
            >
                {Array(steps)
                    .fill("")
                    .map((label, index) => (
                        <Step key={label + index}>
                            <StepLabel StepIconComponent={DotStepIcon} />
                        </Step>
                    ))}
            </Stepper>

            <Grid container direction="column" className={classes.box}>
                <Typography variant="h4" className={classes.title}>
                    Realizá el pago de tu seguro
                </Typography>
                <Typography variant="h6" className={classes.description}>
                    Para continuar el proceso, realizá el pago de tu seguro
                    proporcionando la información requerida <br /> por nuestro
                    proveedor. Luego verificá tus datos y autorizá el cargo por
                    tu primera cuota.
                </Typography>
                <Grid container spacing={7} alignItems={"flex-end"}>
                    <Grid item xs>
                        <CardSummary
                            riskType={riskType}
                            plan={plan}
                            insurance={insurances_}
                            quotationScreenValues={{
                                ...quotationScreenValues,
                                name:
                                    nameInfo[
                                    riskType.toUpperCase().replace(" ", "_")
                                    ] || "unknown",
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Card
                            elevation={0}
                            classes={{
                                root: classes.card,
                            }}
                        >
                            <CardContent
                                classes={{ root: classes.cardContent }}
                            >
                                <Typography>
                                    El inicio de la póliza contratada es <strong>{moment(quotationScreenValues?.quoteData?.VigenciaDesde).format("DD/MM/YYYY")}</strong> a partir de las 12PM.{" "} 
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {!isLoading ? <>
                    {/** PAGO MANUAL (NO PAGADA) */}
                    {!isAlreadyPayed && isManualPayment && (
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate("/quote/payment-approval");
                            }}
                        >
                            Siguiente{" "}
                        </Button>
                    )}

                    {/** METODO DE PAGO PROVENIENTE DE BO */}
                    {!isAlreadyPayed &&
                        !isManualPayment &&
                        !insurances.loading &&
                        paymentListComponent()}

                    {/** COTIZACION PAGADA */}
                    {isAlreadyPayed && isAlreadyPayedComponent()}
                </> : (
                    <div className={classes.loaderContainer}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </Container>
    );
};

export default PaymentComponent;