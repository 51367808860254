import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const getTemplateByIdAPI = createAsyncAction(
    'GET_LAYOUT_ID',
    async (id) => {
        const res = await fetch(`${settings.quotationUrlApi}template/${id}`, { method: 'GET' })
            .then((response) => validateResponse(response))
            .catch((error) => {
                throw exeptionCodeResponse(error);
            });
        
        return res;
    }
);

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};