import React, { useState, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { CircularProgress, Button } from "@material-ui/core";

const GoogleRecaptcha = ({ setTokenRecaptca }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [verification, setVerification] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleReCaptchaVerify = async () => {
        setLoading(true);
        if (executeRecaptcha) {
            const token = await executeRecaptcha("homepage");
            setTokenRecaptca(token);
            setVerification(true);
        }
        setLoading(false);
    };

    return (
        <div>
            {!verification && (
                <div
                    style={{
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {loading ? (
                        <CircularProgress color="primary" />
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReCaptchaVerify}
                        >
                            Validar Captcha
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default GoogleRecaptcha;
