import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import settings from "../../../settings";
import { putUpdateQuotationScreenValues } from "../../../actions/serviceActions";
import { getPaymentStatus } from "../../../actions/paymentActions";
import "../../../styles/done.css";
import LayoutLoading from "../../../components/layout/layoutLoading";
import { PAGADA, PENDIENTE_DE_PAGO } from "../../../utils/scores";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import { navigate, useLocation } from "@reach/router";
import Utils from "../../../utils/utils";
import pagaditoUtils from "../../../utils/pagadito";
import { updateLead } from "../../../actions/leadActions";
import { getQuote } from "../../../actions/quoteActions";
import { toTitleCase } from "../../../utils";

const PaymentApprovedComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const insurances = useSelector((state) => state.insurance);
    const lead = useSelector((state) => state.lead.data);
    const payment = useSelector((state) => state.payment)
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
    const [isReadyToContinue, setIsReadyToContinue] = useState(false);
    const [IsreadyTogo, setIsreadyTogo] = useState(false);
    const [isManualPayment, setIsManualPayment] = useState(false);
    const [paymentStatusDescription, setPaymentStatusDescription] = useState("Pagada");

    const riskType = localStorage.getItem("quotation::risktype")
    const insurance = insurances.data.find((insurance) => insurance.riskType === riskType);

    const [quotation, setQuotation] = useState(null)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get("token")
    const ern = urlParams.get("ern")

    const goTo = {
        payment: () => window.location = `${location.origin}/quote/${localStorage.getItem("quotation::risktype")}/${localStorage.getItem("quotation::idf")}/${localStorage.getItem("quotation::resultQuoteId")}/${localStorage.getItem("quotation::formBuilderId")}/${localStorage.getItem("quotation::id")}/payment`,
        clientVinculation: () => navigate(`${quotation.riskType}/null/${quotation.insuranceId}/null/${quotation._id}/client-vinculation`),
        sumary: () => navigate(`${quotation.riskType}/null/${quotation.insuranceId}/null/${quotation._id}/sumary`)
    } 

    const updateTrackingCallback = async (trackingData,status) => {
        const { _id, tracking } = lead;

        const leadBody = {
            ...lead,
            tracking: `${tracking}, ${trackingData}`,
            status,
            url: window.location.href,
            _id: _id,
        };
    
        await dispatch(updateLead(leadBody));
        setIsreadyTogo(true)
    };

    const getQuoteInfo = async () => {
        const quotationId = localStorage.getItem("quotation::id");
        dispatch(getQuote(quotationId));
    };

    useEffect(() => {
        executeQuotation();
        getQuoteInfo()
        //setIsPaymentSuccessful(isManualPayment)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(insurance && insurance.options && insurance.options.manualPayment){
            const isManualPayment_ = insurance ? insurance.options.manualPayment : false;
            setIsManualPayment(isManualPayment_)
            setIsPaymentSuccessful(isManualPayment_)
            setIsReadyToContinue(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insurance]);

    useEffect( () => {
        const getPaymentStatus_ = async () => {
            if(token){
                await dispatch(getPaymentStatus(token))
            }
        }

        getPaymentStatus_()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]); 

    useEffect(() => {
        if(!isManualPayment && payment && !Utils.isEmptyObject(payment.data)){
            const status = payment.data.value && payment.data.value.status ? payment.data.value.status : undefined
            const statusDescription = pagaditoUtils.pagaditoPaymentStatus[status]
            const isPaymentPassed = pagaditoUtils.pagaditoPaymentPassedStatus[status]

            setPaymentStatusDescription(statusDescription)
            setIsPaymentSuccessful(isPaymentPassed)
            setIsReadyToContinue(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment]); 

    useEffect( () => {
        if(isReadyToContinue && !!quotation ){
            const status = PAGADA
            const tracking = paymentStatusDescription || "No se pudo completar el pago"

            const trackingData = `${new Date()
                .toTimeString()
                .slice(0, 5)}] - ${toTitleCase(
                riskType
            )}: ${tracking}`;
            setIsreadyTogo(true)
            // updateTrackingCallback(trackingData, status)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReadyToContinue, quotation]); 

     useHandleLeadSource(
        {
            isNew: false,
            status: PAGADA,
            trackingStrings: "Pagada",
            riskType:
                quotation && quotation.riskType
                    ? quotation.riskType
                    : localStorage.getItem("quotation::risktype") || "",
        },
        isReadyToContinue && !!quotation       
    );

    useEffect( () => {
        //if (isReadyToContinue) {
        if (IsreadyTogo) {            
            if(isPaymentSuccessful){
                const storeIsPayedttribute_ = async () => {
                    await storeIsPayedttribute(quotation._id)
                 }

                 storeIsPayedttribute_()
            }
            
           if (
                isManualPayment &&
                lead.status === PAGADA &&
                lead.url.search("client-vinculation") > 0
            ) {
                goTo["payment"]()
            }

            if (quotation.riskType.toLowerCase().includes("medicos")) {
                if(isPaymentSuccessful){
                    goTo["clientVinculation"]()
                }else{
                    goTo["payment"]()
                }
            } else {
                goTo["sumary"]()
            }
            // setRiskType(quotation.riskType);
            // dispatch(removeQuote());
            // TODO: handle que users wants email or digital sign
            // sendQuotationMail();
            // submitSign();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IsreadyTogo]);

    const executeQuotation = async () => {
        const quotationId = localStorage.getItem("quotation::id");

        await axios({
            method: "get",
            url: `${settings.quotationUrlApi}quotation/${quotationId}`,
        })
            .then((res) => res.data)
            .then((data) => {
                setQuotation(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const storeIsPayedttribute = async (quotationId) => {
        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: {
                    isAlreadyPayed: isPaymentSuccessful,
                    paymentId: token,
                },
                _id: quotationId,
            })
        );
    };

    return <LayoutLoading />;

};


export default PaymentApprovedComponent;
