import {
    Button,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
    renderNumberField,
    renderRadioGroup,
    renderSelectField,
    renderTextField,
} from "../renderInputsForm";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 100,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 32
    },
    subtitle: {
        fontWeight: 100,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20,
        marginTop: 25
    },
    button: {
        marginRight: theme.spacing(4),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        '& button': {
            marginTop: 8,
            marginBottom: 8
        }
    },
    label: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: 0,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: 0.15008
    },
    small: {
        fontWeight: 300,
        letterSpacing: 0.09,
        lineHeight: '19.2px',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.87)',
    }
}));

const StyledStepLabel = withStyles({
    label: {
        fontWeight: "100 !important",
        color: "rgba(0, 0, 0, 0.87) !important",
        fontSize: 32,
    },
})(StepLabel);

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        "peso",
        "estatura",
        "radio0",
        "radio1",
        "salud1",
        "salud2",
        "salud3",
        "salud4",
        "salud5",
        "salud6",
        "salud7",
        "salud8",
        "salud9",
        "salud10",
        "salud11",
        "salud12",
        "salud13",
        "salud14",
        "salud15",
        "salud16",
        "salud17",
        "salud18",
        "salud19",
        "salud20",
        "salud21",
        "salud22",
        "economicActivityType",
        "economicActivityTypeOther",
        "select0",
        "economicActivityWorkplace",
        "economicActivityOfficeHeld",
        "economicActivityAddress",
        "economicActivityMunicipality",
        "economicActivityWorkPhone",
        "economicActivityAddressDepartment",
        "pepHoldsPublicOffice",
        "pepPositionHeld",
        "pepPositionHeldSince",
        "pepPositionHeldUntil",
        "pepFamilyMemberHoldsOffice",
        "pepRelationship",
        "pepPosition",
        "pepRelativeName",
        "affidavitAdvancePayment",
        "affidavitProjectedMonthlyIncome",
        "radio1_input",
        "radio0_input",
        "salud4_input",
        "salud5_input",
        "salud12_input",
        "salud21_input",
        "salud22_input",
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Requerido";
        }
    });

    if (
        values.peso &&
        (Number(values.peso) < 100 || Number(values.peso) > 350)
    ) {
        errors.peso = "Valor fuera de rango (min: 100, max: 350)";
    }

    if (
        values.estatura &&
        (Number(values.estatura) < 0.6 || Number(values.estatura) > 2.2)
    ) {
        errors.estatura = "Valor fuera de rango (min: 0.60, max: 2.20)";
    }
    return errors;
};

const ClientVinculationForm = ({
    handleSubmit,
    submitting,
    valid,
    onDataReturn,
    goToNextPage,
    quotationScreenValues,
    initialize,
}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [conditionalInputs, setConditionalInputs] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);

    useEffect(() => {
        handleDepartments();
    }, []);

    /* useEffect(() => {
        const allTitles = Array.from(document.querySelectorAll('.client__vinculation-title'));
        setTimeout(() => {
            const nextTitle = allTitles[activeStep];
            window.scrollTo({
                top: nextTitle.offsetTop - nextTitle.offsetHeight,
                behavior: 'smooth'
            });
        }, 700);
    }, [activeStep]); */

    useEffect(() => {
        if (
            quotationScreenValues &&
            quotationScreenValues.isFullfilledClientVinculationForm
        ) {
            const _document = quotationScreenValues.clientVinculation;
            setSelectedJobType(_document && _document.economicActivityType);
            setConditionalInputs(
                _document &&
                Object.keys(_document)
                    .filter((k) => k.includes("_"))
                    .map((k) => k.split("_")[0])
            );
            initialize({
                ..._document,
            });
        }
    }, [quotationScreenValues]);

    useEffect(() => {
        if (selectedDepartment) {
            handleMunicipalities();
        }
    }, [selectedDepartment]);

    const handleScroll = () => {
        const allTitles = Array.from(document.querySelectorAll('.client__vinculation-title'));
        const nextTitle = allTitles[activeStep];
        window.scrollTo({
            top: nextTitle.offsetTop - nextTitle.offsetHeight,
            behavior: 'smooth'
        });
    };

    const handleDepartments = async () => {
        const res = await fetch('https://test-lists.iconnectance.com/listCollection/departamentos/?readonly=1&label=name&value=code')
            .then(res => res.json());

        setDepartments(res);
    };

    const handleSelectDepartment = (e) => {
        setSelectedDepartment(e.target.value);
    };

    const handleMunicipalities = async () => {
        const res = await fetch(`https://test-lists.iconnectance.com/listCollection/municipios/?value=_id&label=name&readonly=1&codeDep=${selectedDepartment}`)
            .then(res => res.json());
        setMunicipalities(res);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmits = async (values) => {
        const department = departments.find(d => d.value === values.economicActivityAddressDepartment);
        const municipality = municipalities.find(d => d.value === values.economicActivityMunicipality);
        const formattedValues = {
            ...values,
            department: department ? department.label : '',
            municipality: municipality ? municipality.label : ''
        };
        await onDataReturn(formattedValues);
        goToNextPage();
    };

    const handleSelectJobType = (e) => {
        setSelectedJobType(e.target.value);
    };

    const options = [
        { value: "1", label: "Si" },
        { value: "0", label: "No" },
    ];

    const jobOptions = [
        { value: "1", label: "Empleado" },
        { value: "2", label: "Comerciante" },
        { value: "3", label: "Profesional independiente" },
        { value: "4", label: "Otro" },
    ];

    const independentJobOptions = [
        "Abogado independiente",
        "Notario independiente",
        "Contador publico independiente",
        "Auditor independiente",
        "Ninguno de los anteriores",
    ];

    const incomeOptions = [
        { value: "1", label: "$1.00 - $500.00" },
        { value: "2", label: "$501.00 - $1,000.00" },
        { value: "3", label: "$1,001 - $5,000" },
        { value: "4", label: "$5,001.00 - $10,000.00" },
        { value: "5", label: "$10,000.00 - $25,000.00" },
        { value: "6", label: "$25,001.00 - $50,000.00" },
        { value: "7", label: "$50,001.00 - $100,000.00" },
        { value: "8", label: "$100,001.00 - más" },
    ];

    const checkConditional = (cond) => {
        return conditionalInputs && !!conditionalInputs.includes(cond);
    };

    const handleConditional = (e) => {
        if (e.target.value == 1) {
            setConditionalInputs(conditionalInputs.concat(e.target.name));
        } else {
            if (checkConditional(e.target.name)) {
                setConditionalInputs(
                    conditionalInputs.filter((c) => c !== e.target.name)
                );
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSubmits)}>
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                    <StyledStepLabel className="client__vinculation-title">
                        Solicitud de Gastos Médicos Individual
                    </StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Typography component="h6" className={classes.subtitle}>
                            Completa los datos del Titular. Recuerda que la
                            información que ingresas es una declaración jurada
                            para la emisión de la póliza
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="peso"
                                        label="Peso (libras)"
                                        type="number"
                                        component={renderNumberField}
                                        custom={{ tabIndex: 1 }}
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <Field
                                        name="estatura"
                                        label="Estatura (metros)"
                                        type="number"
                                        component={renderNumberField}
                                        custom={{ tabIndex: 2 }}
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            ¿Ha tenido usted o alguno de sus
                                            dependientes algún seguro similar al
                                            que esta solicitando?
                                        </legend>
                                        <Field
                                            name="radio1"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 3,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("radio1") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="radio1_input"
                                                label="¿Quién?"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            ¿Usted o alguno de sus dependientes
                                            fuma, consume bebidas alcohólicas,
                                            drogas o estupefacientes?
                                        </legend>
                                        <Field
                                            name="radio0"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 4,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("radio0") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="radio0_input"
                                                label="¿Quién?"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!valid}
                                >
                                    Siguiente
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StyledStepLabel className="client__vinculation-title">Declaración de Salud</StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Typography
                            component="h6"
                            className={classes.subtitle}
                            style={{ marginBottom: 40 }}
                        >
                            Las siguientes preguntas corresponden a su
                            Declaración de Salud
                        </Typography>
                        <Grid container>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            1) ¿Goza usted y todos sus
                                            dependientes de buena salud?
                                        </legend>
                                        <Field
                                            name="salud1"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 5,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            2) ¿Tiene usted o alguno de sus
                                            dependientes algún defecto físico,
                                            congénito o adquirido?
                                        </legend>
                                        <Field
                                            name="salud2"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 6,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            3) ¿Se le ha indicado a usted o
                                            alguno de sus dependiente algún
                                            examen, tratamiento o cirugía para
                                            cualquier atención medica, mental o
                                            nerviosa?
                                        </legend>
                                        <Field
                                            name="salud3"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 7,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            4) ¿Su capacidad de trabajo se ha
                                            visto reducida? ¿En dado caso,
                                            por qué?
                                        </legend>
                                        <Field
                                            name="salud4"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 8,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("salud4") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="salud4_input"
                                                label="¿Por qué?"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            5) ¿Esta usted o alguna dependiente
                                            embarazada? En dado caso indique
                                            quien y de cuantas semanas
                                        </legend>
                                        <Field
                                            name="salud5"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 9,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("salud5") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="salud5_input"
                                                label="¿Quién? E indique cuantas semanas"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!valid}
                                >
                                    Siguiente
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StyledStepLabel className="client__vinculation-title">
                        Padece o ha padecido alguna de las siguientes
                        condiciones
                    </StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            6) Enfermedades del sistema nervioso
                                            (epilepsia, hemiplejia, apoplejia,
                                            polineuritis, convulsiones,
                                            desmayos, derrame, isquemico,
                                            hemorragia cerebral, meninguitis,
                                            neurastenia, parálisis, neurosis,
                                            migraña, vértigo, mal de parkinson,
                                            esclerosis lateralamiotrofic,
                                            distrofia muscular, sindrome de
                                            Guillain barre)
                                        </legend>
                                        <Field
                                            name="salud6"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 10,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            7) Trastorno mentales (psicosis,
                                            esquizofrenia, trastornos bipolares,
                                            ansiedad, trastornos alimenticios,
                                            fatiga crónica, mongolismo, abuso o
                                            dependencia de sustancias química o
                                            drogas, alcoholismo, depresiones)
                                        </legend>
                                        <Field
                                            name="salud7"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 11,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            8) Enfermedades del corazón y del
                                            aparato circulatorio (insuficiencia
                                            cardíaca, ataque cardíaco, angina,
                                            dolor de pecho, arteriosclerosis,
                                            hipertensión, trombosis, flebitis,
                                            fiebre reumática, infarto, sincope,
                                            defecto cardíaco o soplo del
                                            corazón, inflamación de pies o
                                            tobillos, edemas de miembros
                                            inferiores, venas varicosas,
                                            aneurismo, valvulopatias, arritmias)
                                        </legend>
                                        <Field
                                            name="salud8"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 12,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            9) Enfermedades de la sangre
                                            (anemia, leucemia, hepatitis,
                                            ganglios linfáticos, colesterol
                                            alto, deficiencia el factor II, V,
                                            VII, X, XII, deficiencia congénitas
                                            de antitrombina III, púrpura
                                            trombocitopénica idiopática o
                                            trombótica, enfermedad de von
                                            willebrend, defectos de la función
                                            plaquetaria triglicérios altos)
                                        </legend>
                                        <Field
                                            name="salud9"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 13,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            10) Trastorno autoinmunes (anemia
                                            perniciosa, tirodilis de Hashimoto,
                                            enfermedad de addison,
                                            dermalomiositis, esclerosis
                                            múltiple, lupus eritematoso, o
                                            sistemático, sindrome de sjogren,
                                            miastenia grave, bocio tirotoxico
                                            difuso, sindrome de reiter
                                            hemoglobinopatia, hepatitis
                                            autoinmune, anemia hemolítica
                                            autoinmune)
                                        </legend>
                                        <Field
                                            name="salud10"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 14,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            11) Trastorno gastrointestinal
                                            (úlceras, cálculos biliares,
                                            reflujos gastroesofagico, vómitos de
                                            sangre, diverticulis, gastritis,
                                            icticia, hemorroide, fistula del
                                            recto, hernia hiatal, enfermedades
                                            del higado o del páncreas, pólipos
                                            intestinales, colitis, intolerancia
                                            a gluten, enfermedades de Crohn)
                                        </legend>
                                        <Field
                                            name="salud11"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 15,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            12) Enfermedades del sistema
                                            endocrino (diabetes, hiperglucemia,
                                            hipoglucemia, obesidad,
                                            hipotiroidismo, bocio, hipofisis,
                                            hipertiroidismo). De ser afirmativo
                                            para diabetes, indicar tipo de
                                            diabetes, fecha que se diagnosticó,
                                            medicamentos y dosis tratamientos
                                            que lleva
                                        </legend>
                                        <Field
                                            name="salud12"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 16,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("salud12") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="salud12_input"
                                                label="Información extra"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            13) Enfermedades del sistema
                                            osteomuscular (lumbago, ciática,
                                            escoliosis, artritis, tendonitis,
                                            gota, osteoporosis, sindrome del
                                            túnel carpiano, espina bifida,
                                            hernias discales)
                                        </legend>
                                        <Field
                                            name="salud13"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 17,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            14) Enfermedades del sistema
                                            respiratorio (asma, tos crónica,
                                            tuberculosis, enfisema pulmonar,
                                            bronquitis crónica, neumonia
                                            pleuresia, catarro persistente,
                                            enfermedad pulmonar, obstructiva
                                            cronica (EPOC), ronquera crónica,
                                            sinusitis, alergias, fibrosis
                                            pulmonar)
                                        </legend>
                                        <Field
                                            name="salud14"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 18,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            15) Enfermedades del sistema
                                            genito-urinario (tumores o
                                            enfermedades de la próstata,
                                            insuficiencia renal crónica aguda,
                                            cálculo renales, quistes de ovarios,
                                            enfermedades de la vejiga o la
                                            uretra, sangrado genital anormal,
                                            fibroma, displasia, cáncer uterino /
                                            cervix, endometriosis)
                                        </legend>
                                        <Field
                                            name="salud15"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 19,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            16) Enfermedades de transmisión
                                            sexual (síndrome de
                                            inmunodeficiencia adquirida
                                            (SIDA-VIH), virus del papiloma
                                            humano (VPS), sifilis, gonorrea,
                                            hepatitis, B y C, herpes genitales,
                                            condilomas)
                                        </legend>
                                        <Field
                                            name="salud16"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 20,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            17) Enfermedades de los ojos, oídos,
                                            nariz o boca (cataratas, glaucoma,
                                            desviación el tabique nasal,
                                            sinusitis crónica, trastornos de la
                                            articulación, temporomandibular,
                                            (ATM), síndrome de meniere
                                            pterigión)
                                        </legend>
                                        <Field
                                            name="salud17"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 21,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            18) Enfermedades o lesiones de la
                                            piel (lunares que han crecido en
                                            corto tiempo, cambiado de color o
                                            que pican, vitiligio, cáncer en la
                                            piel)
                                        </legend>
                                        <Field
                                            name="salud18"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 22,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            19) Secuelas de enfermedades
                                            infecciosa (escarlatina, difteria
                                            filoideas, septicemias, malaria,
                                            ebola, chikungunya, zika)
                                        </legend>
                                        <Field
                                            name="salud19"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 23,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            20) Enfermedades o trastornos
                                            citados anteriormente (hernias,
                                            cáncer, melanoma, sarcoma de Kaposi,
                                            tumor de cualquier tipo, linfomas,
                                            quistes, deformidad o mutilación de
                                            algún miembro, diarrea persistente,
                                            fiebre de origen desconocidos,
                                            pérdida inexplicable de peso,
                                            sudoración nocturna, enfermedad, o
                                            defecto congénito, desnutrición,
                                            patología de mama, cáncer de mama)
                                        </legend>
                                        <Field
                                            name="salud20"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 24,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            21) ¿Ha recibido transfusiones de
                                            sangre y/o derivados de sangre? En
                                            dado caso indique cuando y por que
                                        </legend>
                                        <Field
                                            name="salud21"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 25,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("salud21") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="salud21_input"
                                                label="¿Cuándo y por qué?"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            22) ¿Ha sufrido algún accidente
                                            grave o intoxicaciones? En dado caso
                                            indique cuando y que lesiones graves
                                            sufrió.
                                        </legend>
                                        <Field
                                            name="salud22"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 26,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                    {checkConditional("salud22") && (
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="salud22_input"
                                                label="Información extra"
                                                component={renderTextField}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!valid}
                                >
                                    Siguiente
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StyledStepLabel className="client__vinculation-title">Ficha integral</StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Grid container>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            Seleccione su actividad económica
                                        </legend>
                                        <Field
                                            name="economicActivityType"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 27,
                                                options: jobOptions,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleSelectJobType}
                                        />
                                    </FormControl>
                                </Grid>
                                {selectedJobType && selectedJobType == 4 && (
                                    <Grid item style={{ marginBottom: 20 }}>
                                        <Field
                                            name="economicActivityTypeOther"
                                            label="En caso de seleccionar otro especifique"
                                            component={renderTextField}
                                            custom={{ tabIndex: 28 }}
                                        />
                                    </Grid>
                                )}
                                {selectedJobType && selectedJobType == 3 && (
                                    <>
                                        <Typography
                                            component="h6"
                                            className={classes.subtitle}
                                        >
                                            En caso de haber elegido
                                            "Profesional independiente",
                                            seleccione la profesión que ejerce
                                        </Typography>
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="select0"
                                                label="Profesión"
                                                component={renderSelectField}
                                                custom={{ tabIndex: 29 }}
                                            >
                                                {independentJobOptions.map(
                                                    (j, i) => (
                                                        <MenuItem
                                                            key={`${j}__${i}`}
                                                            value={j}
                                                        >
                                                            {j}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Field>
                                        </Grid>
                                    </>
                                )}
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityWorkplace"
                                        label="Lugar de trabajo (Empleados)"
                                        component={renderTextField}
                                        custom={{ tabIndex: 29 }}
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityOfficeHeld"
                                        label="Cargo desempeñado"
                                        component={renderTextField}
                                        custom={{ tabIndex: 30 }}
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityAddress"
                                        label="Direccion luegar de trabajo / comercio / ejerce profesion"
                                        component={renderTextField}
                                        custom={{ tabIndex: 31 }}
                                    />
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityAddressDepartment"
                                        label="Departamento"
                                        component={renderSelectField}
                                        custom={{ tabIndex: 32 }}
                                        onChange={handleSelectDepartment}
                                    >
                                        {departments.map((d, i) => (
                                            <MenuItem key={`${d.value}__${i}`} value={d.value}>
                                                {d.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityMunicipality"
                                        label="Municipio"
                                        component={renderSelectField}
                                        custom={{ tabIndex: 33 }}
                                    >
                                        {municipalities.map((d, i) => (
                                            <MenuItem key={`${d.value}__${i}`} value={d.value}>
                                                {d.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                {/* <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityMunicipality"
                                        label="Municipio"
                                        component={renderTextField}
                                        custom={{ tabIndex: 32 }}
                                    />
                                </Grid> */}
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityWorkPhone"
                                        label="Teléfono de trabajo"
                                        component={renderTextField}
                                        custom={{ tabIndex: 34 }}
                                    />
                                </Grid>
                                {/* <Grid item style={{ marginBottom: 20 }}>
                                    <Field
                                        name="economicActivityAddressDepartment"
                                        label="Departamento de trabajo "
                                        component={renderTextField}
                                        custom={{ tabIndex: 34 }}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!valid}
                                >
                                    Siguiente
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StyledStepLabel className="client__vinculation-title">
                        Persona Expuesta Políticamente
                    </StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Typography component="h6" className={classes.subtitle}>
                            Son aquellas que desempeñan o han desempeñado
                            funciones públicas en nuestro país o en su país de
                            origen, así como sus parientes en primer y segundo
                            grado de consanguinidad o afinidad; durante los
                            cinco años siguientes a aquel en que hubiese cesado
                            su nombramiento. Art. 236 y 239 de la Constitución y
                            literales a), b) y c) del artículo 2 de la
                            Convención de las Naciones Unidas Contra la
                            Corrupción.
                        </Typography>
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            ¿Desempeña o ha desempeñado algún
                                            cargo público importante?
                                        </legend>
                                        <Field
                                            name="pepHoldsPublicOffice"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 35,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                </Grid>
                                {checkConditional("pepHoldsPublicOffice") && (
                                    <>
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="pepPositionHeld"
                                                label="Cargo Público desempeñado:"
                                                component={renderTextField}
                                                custom={{ tabIndex: 36 }}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="pepPositionHeldSince"
                                                label="Del:"
                                                component={renderTextField}
                                                custom={{ tabIndex: 36 }}
                                            />
                                        </Grid>
                                        <Grid item style={{ marginBottom: 20 }}>
                                            <Field
                                                name="pepPositionHeldUntil"
                                                label="Al:"
                                                component={renderTextField}
                                                custom={{ tabIndex: 37 }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item style={{ marginBottom: 20 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            ¿Tiene algún familiar en primer o
                                            segundo grado de consanguinidad que
                                            desempeña o ha desempeñado un cargo
                                            como PEP´S?
                                        </legend>
                                        <Field
                                            name="pepFamilyMemberHoldsOffice"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 38,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                            onChange={handleConditional}
                                        />
                                    </FormControl>
                                </Grid>
                                {checkConditional(
                                    "pepFamilyMemberHoldsOffice"
                                ) && (
                                        <>
                                            <Grid item style={{ marginBottom: 20 }}>
                                                <Field
                                                    name="pepRelationship"
                                                    label="Parentesco:"
                                                    component={renderTextField}
                                                    custom={{ tabIndex: 39 }}
                                                />
                                            </Grid>
                                            <Grid item style={{ marginBottom: 20 }}>
                                                <Field
                                                    name="pepPosition"
                                                    label="Cargo:"
                                                    component={renderTextField}
                                                    custom={{ tabIndex: 39 }}
                                                />
                                            </Grid>
                                            <Grid item style={{ marginBottom: 20 }}>
                                                <Field
                                                    name="pepRelativeName"
                                                    label="Nombre del pariente:"
                                                    component={renderTextField}
                                                    custom={{ tabIndex: 40 }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={!valid}
                                >
                                    Siguiente
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StyledStepLabel className="client__vinculation-title">Declaración jurada</StyledStepLabel>
                    <StepContent TransitionProps={{
                        onExited: handleScroll,
                        timeout: {
                            enter: 250,
                            exit: 250
                        }
                    }}>
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item xs={12} style={{ padding: 8 }}>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            ¿Se proyecta realizar pago
                                            anticipado?
                                        </legend>
                                        <Field
                                            name="affidavitAdvancePayment"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 41,
                                                options: options,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 40 }}>
                                    <FormControl component="fieldset">
                                        <legend className={classes.label}>
                                            Estimacion de ingresos proyectados
                                            mensualmente
                                        </legend>
                                        <Field
                                            name="affidavitProjectedMonthlyIncome"
                                            component={renderRadioGroup}
                                            custom={{
                                                tabIndex: 42,
                                                options: incomeOptions,
                                            }}
                                            style={{ flexDirection: "row" }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography component="h6" className={classes.small}>
                            Declaro bajo juramento, por derecho propio, que
                            someto todos los actos que realice a través de
                            cualquier operación que implique, entrega o
                            transferencia de fondos de cualquier tipo, en
                            concepto de pago de primas, pago de préstamos o
                            adquisición de bienes (salvamentos, recuperaciones o
                            activos extraordinarios) bajo cualquier modalidad
                            con ASEGURADORA ABANK, S.A., SEGUROS DE PERSONAS de
                            ninguna manera están relacionados con los delitos
                            generadores de Lavado de Dinero y Activos descritos
                            en el artículo 6 de la ley contra el lavado de
                            Dinero y Activos.{" "}
                        </Typography>
                        <Typography component="h6" className={classes.small}>
                            a) En cumplimiento al Instructivo de la Unidad de
                            Investigación Financiera para la Prevención del
                            Lavado de Dinero y Activos en las Instituciones de
                            Intermediación Financiera, detallamos (detallo) la
                            información que hemos (he) suministrado en la
                            solicitud y en este documento es veraz y nos
                            obligamos (me obligo) a actualizarla mientras
                            mantengamos (mantenga) una relación contractual con
                            ASEGURADORA ABANK, S.A., SEGUROS DE PERSONAS, y
                            autorizo para que pueda ser comprobada en cualquier
                            momento.
                        </Typography>
                        <Typography component="h6" className={classes.small}>
                            b) Eximo a ASEGURADORA ABANK, S.A., SEGUROS DE
                            PERSONAS, de toda responsabilidad que se derive por
                            información errónea, falsa o inexacta que nosotros
                            (yo) hubiéramos (hubiere) proporcionado en este
                            documento, o de la violación del mismo.
                        </Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    variant="outlined"
                                >
                                    ATRÁS
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={submitting || !valid}
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
            </Stepper>
        </form>
    );
};

export default reduxForm({ form: "ClientVinculationForm", validate })(
    ClientVinculationForm
);
