import { useState, useEffect } from "react";
import { useLocation, useParams, navigate, useMatch } from "@reach/router";
import { doesLeadAndQuoatationMatch, toTitleCase } from "../utils";
import Utils from "../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { useQuote } from "./useQuote";
import {
  getLeadAndQuotation,
  updateLead,
  postLead,
} from "../actions/leadActions";

export const useHandleLeadSource = ({
  isNew,
  status,
  trackingStrings,
  riskType,
  trigger = true,
  agentCode = 4378
}) => {
  const dispatch = useDispatch();
  const lead = useSelector((state) => state.lead);
  const stateTemp = useQuote(riskType);
  const quote = Utils.isEmptyObject(stateTemp.quotation) ? [] : stateTemp;

  const location = useLocation();
  const [hadled, setHadled] = useState(false);

  useEffect(() => {
    const leadId = new URLSearchParams(location.search).get("lead");
    const LeadAndQuotationMatch = doesLeadAndQuoatationMatch(
      lead,
      quote.quotation,
      leadId
    );

    if (trigger && !quote.loading && !quote.error && !LeadAndQuotationMatch) {
      handleLeadSource(leadId, quote, riskType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.quotation, trigger]);

  // quote = useQuote(riskType);

  const handleLeadSource = async (leadId, quote) => {
    if (
      leadId == null &&
      quote.quotation &&
      quote.quotation._id &&
      quote.quotation.lead
    ) {
      await handleTracking();
      setHadled(true); // TODO: REMOVE THIS?
    } else {
      if (leadId) {
        setHadled(true); // TODO: REMOVE THIS?
        await dispatch(getLeadAndQuotation(leadId));
      }
    }
  };

  const handleTracking = async () => {
    let leadBody = {};
    const dateString = `[${new Date()
      .toTimeString()
      .slice(0, 5)}] - ${toTitleCase(riskType)}: `;
    const formattedTrackingString = trackingStrings.map(
      (string) => dateString + string
    );
    const { _id, tracking } = lead.data;
    
    if (isNew) {
      leadBody = {
        tracking: formattedTrackingString.join(","),
        status,
        url: location.href,
        product: toTitleCase(riskType),
        userEmail: quote.quotation.lead,
        data: `${riskType}: ${JSON.stringify(quote)}`, // TODO: Keep this until he have the new quote schema
        riskType: riskType,
        quotation: quote.quotation._id,
        agentCode: `0-0-4-${agentCode}`,
        agentName: 'Cotizador Directo',
        quotationNumber: quote.quotation.quotationNumber,
        insuranceId: quote.quotation.insuranceId
      };
      await dispatch(postLead(leadBody));
    } else {
      leadBody = {
        ...lead,
        data: `${riskType}: ${JSON.stringify(quote)}`,
        tracking: `${tracking},${formattedTrackingString.join(",")},`,
        status,
        url: location.href,
        _id,
      };
      await dispatch(updateLead(leadBody));
    }
  };

  return { hadled };
};
