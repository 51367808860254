import React from 'react';
import QuoteTitle from "../../quote/quoteTitle";

const WizardTagsNavigator = ({ group, handleCloseTag, extraFields, handleFillExtraFields, fillingExtraFields, handleDeleteExtraField, handleDeleteExtraFieldTag }) => {
  const getFilteredFieldsByIndex = (index) => {
    return extraFields[index].filter(f => f.some(_f => _f.value !== null));
  }
  const completedSteps = group.fields.filter(f => f.value !== null);
  return (
    !group.multiple ?
      <QuoteTitle
        tags={completedSteps}
        handleCloseTag={handleCloseTag}
        show
        wizard
      /> : <QuoteTitle
        title=""
        tags={getFilteredFieldsByIndex(group.groupIndex)}
        handleCloseTag={handleCloseTag}
        show
        multiple={group.multiple}
        handleFillExtraFields={() => handleFillExtraFields(group.groupIndex)}
        fillingExtraFields={fillingExtraFields}
        handleDeleteExtraField={handleDeleteExtraField}
        groupIndex={group.groupIndex}
        max={group.max}
        handleDeleteExtraFieldTag={handleDeleteExtraFieldTag}
        itemTitle={group.itemTitle}
        groupTitle={group.groupTitle}
        wizard
        groupPlaceholder={group.groupPlaceholder}
      />
  );
}

export default WizardTagsNavigator;