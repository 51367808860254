import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';

export const sendPagaditoPayment = async (productsDetails) => {
    const body = JSON.stringify(productsDetails);
    const headers = {
        'Content-Type': 'application/json'
    };

    const res = await fetch(`${settings.quotationUrlBase}pagadito/sendPayment`, { method: 'POST', headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
}

export const getPaymentStatusAPI = createAsyncAction('GET_PAYMENT_STATUS', async (ern) => {
    const body = JSON.stringify({ern});
    const headers = {
        'Content-Type': 'application/json'
    };

    const res = await fetch(`${settings.quotationUrlBase}pagadito/getPaymentStatus`, { method: 'POST', headers, body })
        .then((response) => validateResponse(response))
        .catch((error) => {
            throw exeptionCodeResponse(error);
        });
    return res;
});

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = 'error') => {
    return error;
};
