import React, { useEffect, useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import styled from "styled-components";
import CardPlan from "../quote/quoteCard";
import ProposalCard from "../proposal/proposalCard";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

const StyledCustomCarousel = styled.div`
  & {
    width: 100%;
    padding: 0px ${props => props.separated ? '0' : '30'}px;
  }

  & .preview-placeholder {
    color: var(--mvs-color-purple);
    font-family: "Montserrat Regular";
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 218px;
  }

  & .preview-placeholder.public {
    color: var(--mvs-color-yellow);
  }

  & .carousel {
    min-width: 312px;
    max-width: 788px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:not(.separated) {
      text-align: center;

      & .carousel__screen {
        overflow: unset;
      }
    }

    &.separated {
      max-width: 1064px;
      min-width: 421px;

      & .carousel__item {
        width: ${props => props.mobileCount > 1 ? 290 : 1064}px;
        margin: 0px 20px;

        &:first-child {
          margin-left: 0px
        }

        &:last-child {
          margin-right: 0px
        }
      }

      & .carousel__controls {
        align-items: flex-start;
      }

      & .carousel__control {
        align-items: flex-start;
      }

      & .carousel__controls .carousel__control--forward {
        transform: translateY(-35px);
      }
    
      & .carousel__controls .carousel__control--backward {
        transform: translateX(0px);
      }

      & .carousel__track {
        margin-top: 45px;
        display: ${props => props.mobileCount > 2 ? 'block' : 'flex'};
        justify-content: center;
      }

    }
  }

  & .carousel__activator {
    display: none;
  }
  
  & .carousel__controls {
    display: none;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  & .carousel__controls:first-of-type {
    justify-content: flex-end;
  }

  & .carousel__controls:last-of-type {
    justify-content: flex-start;
  }

  & .carousel__controls .carousel__control--forward {
    transform: translateX(48px);
  }

  & .carousel__controls .carousel__control--backward {
    transform: translateX(-48px);
  }
  
  & .carousel__control {
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    color: var(--mvs-color-purple);
    border-radius: 100%;
    font-size: 18px;
    -webkit-box-pack: center;
    justify-content: center;
    transition: all 0.3s ease 0s;
    width: 32px;
    z-index: 1;
    padding: 5px;
  }

  & .carousel__control:hover {
    color: var(--mvs-color-purple);
  }

  & .carousel__control.public,
  & .carousel__control.public:hover {
    color: var(--mvs-color-yellow);
    border-color: var(--mvs-color-yellow);
  }

  ${props => props.count ? Array(props.count).fill().map((c, i) => (`
    & .carousel__activator.${props.type}:nth-of-type(${i + 1}):checked
    ~ .carousel__controls:nth-of-type(${i + 1}) {
      display: flex;
    }
    
    & .carousel__activator.${props.type}:nth-of-type(${i + 1}):checked
    ~ .carousel__screen .carousel__track {
      -webkit-transform: translateX(${(i) * -25}%);
              transform: translateX(${(i) * -25}%);
    }

    & .carousel.separated .carousel__activator.${props.type}:nth-of-type(${i + 1}):checked
    ~ .carousel__screen .carousel__track {
      -webkit-transform: translateX(${(i) * -33.33}%);
              transform: translateX(${(i) * -33.33}%);
    }
  `)) : ''}

  & .carousel__screen {
    overflow: hidden;
  }
  
  & .carousel__track {
    font-size: 0;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-right: 40px;
  }
  
  & .carousel__item {
    display: inline-flex;
    vertical-align: top;
    white-space: normal;
    color: #fff;
    margin: 0 5px;
    width: 25%;
    padding: 5px 0px;
    min-height: 770.4px;
  }


  & .carousel__item.info p {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, .87);
    width: 100%;
    text-align: center;
  }

  @media (max-width: 992px) {
    & {
      padding: 0px 10px;
    }

    & .carousel__controls {
      display: none !important;
    }

    ${props => props.mobileCount ? Array(props.mobileCount).fill().map((c, i) => (`
      & .carousel__activator.${props.type}:nth-of-type(${i + 1}):checked
      ~ .carousel__screen .carousel__track {
        -webkit-transform: translateX(${(i) * (props.type === 'plan' ? -0 : -100)}%);
                transform: translateX(${(i) * (props.type === 'plan' ? -0 : -100)}%);
      }
    `)) : ''}

    & .carousel__track {
      padding-right: 0px;
    }

    & .carousel__item {
      width: 100%;
      margin: 0px;
    }
  }

  @media (max-width: 576px) {
    .carousel {
      max-width: 320px;
    }
  }
`;

const CustomCarousel = ({ items, placeholder, handleSelect, selectedItem, index, handleChangeIndex, type, separated, handleNext, quotation, optionsByQuote, insurance }) => {
  //const [index, setIndex] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
        if (type === 'plan') {
          handleChangeIndex(1);
        }
      } else {
        setIsMobile(false);
        if (type === 'plan') {
          handleChangeIndex(1);
        }
      }
  
  
      function handleResize() {
        if (window.innerWidth <= 992) {
          setIsMobile(true);
          if (type === 'plan') {
            handleChangeIndex(1);
          }
        } else {
          setIsMobile(false);
          if (type === 'plan') {
            handleChangeIndex(1);
          }
        }
      }
  
      window.addEventListener('resize', handleResize)
  
      return _ => {
        window.removeEventListener('resize', handleResize)
      };
  }, []);

  /* useEffect(() => {
    if (isMobile) {
      const pages = items.length;
      if (index > pages) {
        setIndex(pages === 0 ? 1 : pages);
      }
    } else {
      const pages = Array(items.filter((_, i) => i >= 2).length).fill().length;
      if (index > pages) {
        setIndex(pages === 0 ? 1 : pages);
      }

      if (pages > index) {
        setIndex(pages);
      }
    }

    
  }, [items]); */

  const sliderPages = Array(items.filter((_, i) => i >= (separated ? 2 : 3)).length).fill();

  /* const handleChangeIndex = (newIndex) => {
    setIndex(newIndex);
  }; */
  return (
    <StyledCustomCarousel count={sliderPages.length} mobileCount={items.length} type={type} separated={separated}>
      {items.length > 0 ?
        <div className={`carousel ${separated ? 'separated' : ''}`}>
          {!isMobile ? <>
            {sliderPages.map((s, i) => (
              <input key={`desktop_carousel__activator__${type}__${i}`} checked={index === i + 1} onChange={() => handleChangeIndex(i + 1)} className={`carousel__activator ${type}`} id={`desktop_carousel-slide-activator-${type}-${i + 1}`} name={`${type}-carousel`} type='radio' />
            ))}
            {/* Arrow in the top carousel */}
            {(sliderPages.length >= (separated ? 2 : 3) && type === 'plan') && <>
              {sliderPages.map((s, i) => {
                const slideIndex = i + 1;
                if (i === 0) {
                  return (
                    <div key={`desktop_carousel__first__controls__${i}`} className='carousel__controls'>
                      <label className={`carousel__control carousel__control--forward`} htmlFor={`desktop_carousel-slide-activator-${type}-${slideIndex + 1}`}>
                        {separated ? <ChevronRightRoundedIcon style={{ color: '#4C1DB7', fontSize: 81 }} /> : <ArrowForwardIos />}
                      </label>
                    </div>
                  );
                } else if (i === sliderPages.length - 1) {
                  return (
                    <div key={`desktop_carousel__last__controls__${i}`} className='carousel__controls'>
                      <label className={`carousel__control carousel__control--backward`} htmlFor={`desktop_carousel-slide-activator-${type}-${slideIndex - 1}`}>
                        {separated ? <ChevronLeftRoundedIcon style={{ color: '#4C1DB7', fontSize: 81 }} /> : <ArrowBackIos />}
                      </label>
                    </div>
                  );
                }

                return (
                  <div key={`desktop_carousel__controls__${i}`} className='carousel__controls'>
                    <label className={`carousel__control carousel__control--backward`} htmlFor={`desktop_carousel-slide-activator-${type}-${slideIndex - 1}`}>
                      {separated ? <ChevronLeftRoundedIcon style={{ color: '#4C1DB7', fontSize: 81 }} /> : <ArrowBackIos />}
                    </label>
                    <label className={`carousel__control carousel__control--forward`} htmlFor={`desktop_carousel-slide-activator-${type}-${slideIndex + 1}`}>
                      {separated ? <ChevronRightRoundedIcon style={{ color: '#4C1DB7', fontSize: 81 }} /> : <ArrowForwardIos />}
                    </label>
                  </div>
                );
              })}
            </>}
            <div className='carousel__screen'>
              <div className='carousel__track'>
                {items.map((item, key) => (
                  type === 'plan' ? (
                    <div key={`desktop_plan__card__${key}`} className='carousel__item' onClick={() => handleSelect(key, item)}>
                      {separated ? (
                        <ProposalCard handleSelect={handleSelect} product={item} index={key} isSelected={key === selectedItem} handleNext={handleNext} products={items} quotation={quotation} optionsByQuote={optionsByQuote} insurance={insurance} />
                      ) : (
                        <CardPlan handleSelect={handleSelect} plan={item} index={key} isSelected={key === selectedItem} quotation={quotation} products={items} isFirst={key === 0} />
                      )}
                    </div>
                  ) : (
                    <div key={`desktop_info__card__${key}`} className='carousel__item info'>
                      <p>{item.limitImportCoverageMax ? `$${item.limitImportCoverageMax.toLocaleString('en-AR')}` : '-'}</p>
                    </div>
                  )
                ))}
              </div>
            </div>
          </> : <>
              {items.map((s, i) => (
                <input key={`mobile_carousel__activator__${type}__${i}`} checked={index === i + 1} onChange={() => handleChangeIndex(i + 1)} className={`carousel__activator ${type}`} id={`mobile_carousel-slide-activator-${type}-${i + 1}`} name={`${type}-carousel`} type='radio' />
              ))}

              {(items.length > 1 && type === "plan") && <>
                {items.map((s, i) => {
                  const slideIndex = i + 1;
                  if (i === 0) {
                    return (
                      <div key={`mobile_carousel__first__controls__${i}`} className='carousel__controls'>
                        <label className={`carousel__control carousel__control--forward`} htmlFor={`mobile_carousel-slide-activator-${type}-${slideIndex + 1}`}>
                          <ArrowForwardIos />
                        </label>
                      </div>
                    );
                  } else if (i === items.length - 1) {
                    return (
                      <div key={`mobile_carousel__last__controls__${i}`} className='carousel__controls'>
                        <label className={`carousel__control carousel__control--backward`} htmlFor={`mobile_carousel-slide-activator-${type}-${slideIndex - 1}`}>
                          <ArrowBackIos />
                        </label>
                      </div>
                    );
                  }

                  return (
                    <div key={`mobile_carousel__controls__${i}`} className='carousel__controls'>
                      <label className={`carousel__control carousel__control--backward`} htmlFor={`mobile_carousel-slide-activator-${type}-${slideIndex - 1}`}>
                        <ArrowBackIos />
                      </label>
                      <label className={`carousel__control carousel__control--forward`} htmlFor={`mobile_carousel-slide-activator-${type}-${slideIndex + 1}`}>
                        <ArrowForwardIos />
                      </label>
                    </div>
                  );
                })}
              </>}
              <div className='carousel__screen'>
                <div className='carousel__track'>
                  {type === 'plan' ? (
                    <SwipeableViews index={index - 1} onChangeIndex={(index) => handleChangeIndex(index + 1)} resistance>
                      {items.map((item, key) => (
                          <div key={`mobile_plan__card__${key}`} className='carousel__item' onClick={() => handleSelect(key, item)}>
                            <CardPlan plan={item} isSelected={key === selectedItem} quotation={quotation} />
                          </div>
                      ))}
                    </SwipeableViews>
                    ) : (
                    items.map((item, key) => (
                      <div key={`mobile_info__card__${key}`} className='carousel__item info'>
                        <p>{item.limitImportCoverageMax ? `$${item.limitImportCoverageMax.toLocaleString('en-AR')}` : '-'}</p>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </>}
        </div>
        :
        <div className={`preview-placeholder`}>
          <span>{placeholder}</span>
        </div>}
    </StyledCustomCarousel>
  )
}

export default CustomCarousel;