import React from 'react';
import { TextField, Checkbox, FormControlLabel, FormControl, RadioGroup, FormHelperText, Radio } from '@material-ui/core';
import InputMask from 'react-input-mask';
// import InputComponent from './customDateInputComponent';

export const renderTextField = ({ input, label, custom, meta: { touched, error } }) => {
    return (
        <TextField
            className="formInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
            {...custom}
        />
    );
};

export const RenderTextFieldDisabled = ({
    input,
    label,
    custom,
    multiline = false,
    rows = 1,
}) => (
    <TextField
        label={label}
        className="formInput"
        margin="normal"
        disabled
        multiline={multiline}
        rows={rows}
        fullWidth
        {...input}
        {...custom}
    />
);

export const renderNumberField = ({ input, label, custom, meta: { touched, error } }) => {
    return (
        <TextField
            className="formInput"
            label={label}
            type="number"
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
            {...custom}
        />
    );
};

export const renderTextFieldOutlined = ({ input, label, custom, meta: { touched, error } }) => {
    return (
        <TextField
            className={custom}
            label={label}
            variant="outlined"
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
        />
    );
};

export const renderPhoneField = ({ input, label, meta: { touched, error } }) => {
    return (
        <InputMask mask="+54 (99) 9999 9999" maskChar="_" {...input} >
            {() =>
                <TextField
                    className="formInput"
                    label={label}
                    error={touched && !!error}
                    helperText={touched && !!error ? error : false}
                    fullWidth
                />
            }
        </InputMask>
    );
};

export const renderSelectField = ({ input, label, children, meta: { touched, error } }) => {
    return (
        <TextField
            select
            className="formInput"
            label={label}
            children={children}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
        />
    );
};

export const renderDateField = ({ input, label, custom, meta: { touched, error } }) => {
    const value = input.value;
    return (
        <TextField
            type="date"
            className="formInput"
            label={label}
            error={touched && !!error}
            helperText={touched && !!error ? error : false}
            fullWidth
            {...input}
            {...custom}
            // InputProps={{

            //     inputComponent: InputComponent

            // }}
            value={value}
            InputLabelProps={{
                shrink: true
            }}
        />
    );
};

export const renderCheckbox = ({ input, label }) => (
    <FormControlLabel
        control={<Checkbox color="primary" checked={!!input.value} onChange={input.onChange} />}
        label={label}
    />
);

export const renderRadioGroup = ({
    input,
    custom: { options },
    meta: { touched, error },
    ...rest
}) => (
    <FormControl>
        <RadioGroup value={input.value} onChange={input.onChange} {...rest}>
            {options.map((option) => (
                <FormControlLabel
                    key={`${input.name}__${option.value}`}
                    value={option.value}
                    control={<Radio color="primary" name={input.name} />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
        {touched && !!error && (
            <FormHelperText error={true}>{error}</FormHelperText>
        )}
    </FormControl>
);
