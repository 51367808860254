// import { FormConsumption } from "form-builder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate, useParams, useLocation } from "@reach/router";
import { Container, Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import Handlebars from "handlebars";
import LayoutLoading from "../../../components/layout/layoutLoading";
import LayoutError from "../../../components/layout/layoutError";
import { putUpdateQuotationScreenValues } from "../../../actions/serviceActions";
import { useQuote } from "../../../hooks/useQuote";
import settings from "../../../settings";
import { useHandleLeadSource } from "../../../hooks/useHandleLeadSource";
import { getQuote, patchPlan } from "../../../actions/quoteActions";
import { getIMC } from "../../../actions/serviceActions";
import { getTemplateById } from "../../../actions/templateAction";
import { PAGADA } from "../../../utils/scores";
import Utils from "../../../utils/utils";
import {
    DotConnector,
    DotStepIcon,
} from "../../../components/styled-stepper/styleDotStepper";
import { getLeadByQuotationId } from "../../../actions/leadActions";
import ClientVinculationForm from "../../../components/form/layouts/clientVinculationForm";

const ClientVinculationComponent = ({ riskType }) => {
    const dispatch = useDispatch();
    const formId = settings.vinculationFormID;
    const urlParams = useParams();
    const quote = useQuote(riskType.toUpperCase());
    const { plan, quotation } = quote;
    const { quotationScreenValues } = quotation;

    const lead = useSelector((state) => state.lead);
    const quoteAllPlans = useSelector((state) => state.quoteAllPlans);
    const template = useSelector((state) => state.template);
    const insurances = useSelector((state) => state.insurance);

    const [isSendMail, setIsSendMail] = useState(false);

    const location = useLocation();
    const queryString = window.location.search;
    const urlParamsQuery = new URLSearchParams(queryString);
    const isStoredLink = !!urlParamsQuery.get("lead");
    const quotationId =
        urlParams && urlParams.resultQuoteId
            ? urlParams.resultQuoteId
            : localStorage.getItem("quotation::id");

    const now = moment().add(1, "day");
    const end = now.clone().add(1, "y");

    const isAlreadyPayed =
        quotationScreenValues && quotationScreenValues.isAlreadyPayed
            ? quotationScreenValues.isAlreadyPayed
            : false;

    useHandleLeadSource({
        isNew: false,
        status: PAGADA,
        trackingStrings: ["Información medica del asegurado"],
        riskType: riskType.toUpperCase(),
        isAlreadyPayed,
    });

    const next = () => {
        navigate("face-recognition");
    };

    const getQuoteInfo = async () => {
        dispatch(getQuote(quotationId));
        dispatch(getLeadByQuotationId(quotationId));
    };

    const recoverPlanInfo = async () => {
        if (
            quoteAllPlans.quotation.products &&
            quoteAllPlans.quotation.products.length &&
            quoteAllPlans.quotation.productSelected
        ) {
            const reconveredPlan = quoteAllPlans.quotation.products.find(
                (item) => item._id === quoteAllPlans.quotation.productSelected
            );

            if (!Utils.isEmptyObject(reconveredPlan)) {
                dispatch(patchPlan(reconveredPlan));
            }
        }
    };

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            getQuoteInfo();
        }
    }, []);

    useEffect(() => {
        if (lead && lead.status === "Emitida") {
            navigate("/quote/completed");
        }
    }, []);

    useEffect(() => {
        if (
            quotationScreenValues &&
            quotationScreenValues.isAlreadyPayed !== undefined &&
            !quotationScreenValues.isAlreadyPayed
        ) {
            window.location = `${location.origin}/quote/${localStorage.getItem(
                "quotation::risktype"
            )}/${localStorage.getItem("quotation::idf")}/${localStorage.getItem(
                "quotation::resultQuoteId"
            )}/${localStorage.getItem(
                "quotation::formBuilderId"
            )}/${localStorage.getItem("quotation::id")}/payment`;
        }
    }, [quotationScreenValues && quotationScreenValues.isAlreadyPayed]);

    useEffect(() => {
        if (quote && Utils.isEmptyObject(plan)) {
            recoverPlanInfo();
        }
    }, [quote]);

    useEffect(() => {
        // if the url come from BO and the speed form are fullfiled then should be go to the next step
        if (
            isStoredLink &&
            quoteAllPlans &&
            quoteAllPlans.quotation &&
            quoteAllPlans.quotation.quotationScreenValues &&
            quoteAllPlans.quotation.quotationScreenValues
                .isFullfilledClientVinculationForm
        ) {
            const isFullfilledForm =
                quoteAllPlans.quotation.quotationScreenValues
                    .isFullfilledClientVinculationForm;
            if (isFullfilledForm) {
                next();
            }
        }
    }, [quoteAllPlans]);

    const passedRules = async (data) => {
        // rules:
        // Salud1 = Si => 1) ¿Gozan usted y todos sus dependientes de buena salud?
        // Peso <170 y Altura < 1.65
        // Las demas = No

        let isPassedRules = true;
        let index = 0;
        let weight = 0;
        let height = 0;

        while (isPassedRules && index < data.length) {
            const value = Object.values(data[index])[0];
            const question = Object.keys(data[index])[0];

            if (question === "salud1" && value !== "1") {
                isPassedRules = false;
            }

            if (question === "estatura") {
                height = value;
            }

            if (question === "peso") {
                weight = value;
            }

            // todas las preguntas de salud diferentes a la pregunta de salud1 deben ser igual a NO
            if (
                value === "1" &&
                question.includes("salud") &&
                question !== "salud1"
            ) {
                isPassedRules = false;
            }

            index++;
        }

        const imc = await getIMC(weight, height);

        /*
            Nota: 
            
            Esta es la ultima condicion que se pidio para emitir directamente

            Si (IMC normal o IMC sobrepeso o IMC obesidad I o IMC obesidad II) y todas preguntas No y pregunta de buena salud Si
            o lo que es lo mismo
            Si (!IMC Bajo Peso && !IMC Obesidad Extrema) y todas preguntas No y pregunta de buena salud Si
        */

        isPassedRules =
            imc.imcType !== "Bajo Peso" &&
            imc.imcType !== "Obesidad Extrema" &&
            isPassedRules;

        return {
            imc,
            isPassedRules,
        };
    };

    const handleDataReturn = async (data) => {
        const formattedData = Object.keys(data).map((key) => ({
            [key]: data[key],
        }));
        const { isPassedRules, imc } = await passedRules(formattedData);
        const healthDeclaration = getDataSpeedForm(formattedData, "salud");
        const economicActivity = getDataSpeedForm(
            formattedData,
            "economicActivity"
        );
        const affidavit = getDataSpeedForm(formattedData, "affidavit");
        const pep = getDataSpeedForm(formattedData, "pep");

        sendMail(healthDeclaration);

        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: {
                    isFullfilledClientVinculationForm: !!data,
                    emitOrRequest: isPassedRules,
                    imc: imc,
                    healthDeclaration,
                    economicActivity,
                    affidavit,
                    pep,
                    clientVinculation: data,
                },
                _id: quotationId,
            })
        );
    };

    useEffect(() => {
        if (riskType && insurances.data.length) {
            const insurance = insurances.data.find(
                (insurance) => insurance.riskType === riskType
            );
            dispatch(getTemplateById(insurance.options.layoutHealth));
        }
    }, [riskType, insurances]);

    const sendMail = async (dataHealthDeclaration) => {
        let quotationData = {};
        if (
            lead &&
            lead.data._id &&
            quotation &&
            quotation._id &&
            !isSendMail &&
            quotationScreenValues &&
            !Utils.isEmptyObject(quotationScreenValues)
        ) {
            setIsSendMail(true);

            const userName = `${quotationScreenValues.name} ${quotationScreenValues.surname}`;
            const redirectleadURL = `${settings.quotationUrlBase}lead/${lead.data._id}`;

            if (quotation && quotation.creationDate) {
                quotationData = { ...quotation };
                quotationData.creationDate = new Date(
                    quotation.creationDate
                ).toLocaleDateString();
            }

            const headers = { "Content-Type": "application/json" };

            const emailTemplate = Handlebars.compile(template.data.newTemplate);
            const dataEmail = {
                redirectleadURL,
                name: userName,
                plan: { name: plan.name },
            };

            await axios({
                method: "post",
                headers,
                url: `${settings.quotationUrlApi}contact/quotation/send`,
                data: {
                    emailDataToSend: {
                        subject: "Gracias por confiar en Aseguradora ABANK",
                        message: emailTemplate(dataEmail),
                        email: quotation.lead,
                    },
                },
            });
        }
    };

    const getDataSpeedForm = (data, searchParameter) => {
        const result = {};
        data.filter((item) =>
            Object.keys(item)[0].includes(searchParameter)
        ).map((item) => {
            const key = Object.keys(item)[0];
            const value = Object.values(item)[0];
            result[key] = value;
        });

        return result;
    };

    const UpdateScreenValues = async (data) => {
        const quotationId = urlParams.resultQuoteId;
        await dispatch(
            putUpdateQuotationScreenValues({
                quotationScreenValues: data,
                _id: quotationId,
            })
        );
    };

    const steps = 7;
    return (
        <Container>
            <Stepper
                style={{ backgroundColor: "transparent" }}
                alternativeLabel
                activeStep={3}
                connector={<DotConnector />}
            >
                {Array(steps)
                    .fill("")
                    .map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={DotStepIcon} />
                        </Step>
                    ))}
            </Stepper>
            {quote.loading && <LayoutLoading />}
            {quote.error && <LayoutError />}
            {!quote.loading && quote.quotation && quote.quotation.products && (
                <Grid item xs className="cardAppContainerFormBuilder">
                    {/* <FormConsumption
                        onDataReturn={handleDataReturn}
                        id={formId}
                        paramToSave={quotationId}
                        showTitle={false}
                        redirect={next}
                    /> */}
                    <ClientVinculationForm
                        onDataReturn={handleDataReturn}
                        goToNextPage={next}
                        quotationScreenValues={quotationScreenValues}
                    />
                </Grid>
            )}
        </Container>
    );
};

export default ClientVinculationComponent;
