import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Home from "./ui/home";
import Faq from "./ui/faq";
import Refer from "./ui/refer";
import NewOverview from "./ui/news/newOverview";
import LayoutHeader from "./components/layout/layoutHeader";
import Quotation from "./ui/quotation/quotationContainer";
import ShowPaymentLink from "./ui/paymentsLinks/showPayments"
import SuccessPaymentLink from  "./ui/paymentsLinks/successPaymentLink"
import FailPaymentLink from  "./ui/paymentsLinks/failPaymentLink"

import {
  ClientInfoComponent,
  PaymentApprovedComponent,
  ProductDetailsComponent,
  ProductInfoComponent,
  ProposalComponent,
  QuotationSumaryComponent,
  FirmApprovedComponent,
  EmittedApprovedComponent,
  CongratsComponentApproved,
  CongratsComponentNotApproved,
  TransitionComponent
} from "./ui/quotation/components";

import TransitionRouter from "./router/TransitionRouter";

import ZequeTest from "./components/dynamicForm";
import RecognitionComponent from "./ui/quotation/components/recognitionComponent";
import PaymentComponent from "./ui/quotation/components/paymentComponent";
import ClientVinculationComponent from "./ui/quotation/components/clientVinculationComponent";
import { useLocation } from "@reach/router";
import settings from "./settings";
import CustomSnackbar from "./components/messages";
import InspectionComponent from "./ui/quotation/components/inspectionComponent";
import { useDispatch } from "react-redux";
import { authSiseUser } from "./actions/siseAuthActions";

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#E50AFA",
      main: "#6716F5",
      dark: "#141B6A",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#0BCEE0",
      main: "#00A0F8",
      dark: "#141B6A",
      contrastText: "#FFFFFF",
    },
    variants: {
      variant1: "#E4DAF9", //variant2 BO
      variant2: "#D8D0F6", //variant3 BO
      variant3: "#C8B4F3", //variant4 BO
      variant4: "#AF8AFC", //variant5 BO
    },
    typography: {
      main: "#4B4B4B",
    },
  },
  typography: {
    fontFamily: "Museo Sans, sans-serif",
  },
});

const Routes = () => {
  const [theme, setTheme] = useState({
    primary: defaultTheme.palette.primary,
    secondary: defaultTheme.palette.secondary,
    variants: defaultTheme.palette.variants,
  });
  const [textFieldVariant, setTextFieldVariant] = useState('standard');
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();

  const location = useLocation();
  const [customHeader, setCustomHeader] = useState(null);

  useEffect(() => {
    handleAuthUser();
  }, []);

  const handleAuthUser = async () => {
    await dispatch(authSiseUser());
  };
  useEffect(() => {
    if (config.data.theme) {
      setTheme({
        primary: {
          ...theme.primary,
          main: config.data.theme.primary.main,
          dark: config.data.theme.primary.variant1,
        },
        secondary: {
          ...theme.secondary,
          main: config.data.theme.secondary.main,
          light: config.data.theme.secondary.variant1,
        },
        variants: {
          variant1: config.data.theme.secondary.variant2,
          variant2: config.data.theme.secondary.variant3,
          variant3: config.data.theme.secondary.variant4,
          variant4: config.data.theme.secondary.variant5,
        },
        typography: {
          main: config.data.theme.typography.main,
          variant1: config.data.theme.typography.variant1,
        },
      });
      setTextFieldVariant(config.data.theme.controlType ?? 'standard');
    }

    if (config.data.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href = config.data.favicon;
    }
  }, [config.data]);

  useEffect(() => {
    if (!location.pathname.includes("quote")) {
      setCustomHeader(null);
    }
  }, [location]);

  const appTheme = createMuiTheme({
    palette: theme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 769,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    props: {
      MuiTextField: {
        variant: textFieldVariant
      }
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 0
        }
      },
      MuiInputLabel: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '80%',
          height: '100%'
        },
        shrink: {
          maxWidth: '109%'
        }
      }
    }
  });

  const handleSetCustomHeader = (template) => {
    setCustomHeader(template)
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <LayoutHeader customHeader={customHeader} />
      {!config.loading && (
        <TransitionRouter>
          <Home path="/" />
          <Faq path="faq" />
          <Refer path="refer" />
          <TransitionComponent path="transition" />
          <ShowPaymentLink path="payment-link/success/:paymentId" />
          <SuccessPaymentLink path="payment-link/redirectAfterPaymentSuccess/:external_reference/:paymentId" />
          <FailPaymentLink path="payment-link/redirectAfterPaymentFail/:external_reference/:paymentId" />

          <NewOverview path="news/:slug" />
          <ZequeTest path="test" />

          <PaymentApprovedComponent path="quote/payment-approval" />
          <CongratsComponentApproved path="quote/completed" />
          <CongratsComponentNotApproved path="quote/completedNeedApproval" />
          {/* <FirmApprovedComponent path="quote/completed" /> */}
          <FirmApprovedComponent path="quote/completed/:paymentTokens" />
          <EmittedApprovedComponent path="quote/emitted" />
          {/* <Quotation path="quote/:riskType/:id">
                          <ProductInfoComponent path="info" />
                          <ProposalComponent path="proposal" />
                          <ClientInfoComponent path="client-info" />
                          <ProductDetailsComponent path="product-details" />
                          <QuotationSumaryComponent path="sumary" />
                        </Quotation> */}
          <Quotation
            showHeader={customHeader === null}
            handleSetCustomHeader={handleSetCustomHeader}
            path="quote/:riskType/:id/:insuranceId/:formBuilderId"
          >
            <ProductInfoComponent path="info" />
            {/* <ClientInfoComponent path="client-info" /> */}
            {/* add param route */}
            <div path=":resultQuoteId">
              <ProposalComponent path="proposal" />
              <RecognitionComponent path="recognition" />
              <ClientInfoComponent path="client-info" />
              <InspectionComponent path="inspection" />
              <PaymentComponent path="payment" />
              <ClientVinculationComponent path="client-vinculation" />
              <RecognitionComponent path="face-recognition" />
              <ProductDetailsComponent path="product-details" />
              <QuotationSumaryComponent path="sumary" />
            </div>
          </Quotation>

          <Quotation
            showHeader={customHeader === null}
            handleSetCustomHeader={handleSetCustomHeader}
            path="embed/quote/:riskType/:id/:insuranceId/:formBuilderId"
          >
            <ProductInfoComponent path="info" />
            {/* <ClientInfoComponent path="client-info" /> */}
            {/* add param route */}
            <div path=":resultQuoteId">
              <ProposalComponent path="proposal" />
              <RecognitionComponent path="recognition" />
              <ClientInfoComponent path="client-info" />
              <InspectionComponent path="inspection" />
              <PaymentComponent path="payment" />
              <ClientVinculationComponent path="client-vinculation" />
              <RecognitionComponent path="face-recognition" />
              <ProductDetailsComponent path="product-details" />
              <QuotationSumaryComponent path="sumary" />
            </div>
          </Quotation>
        </TransitionRouter>
      )}
      <CustomSnackbar />
    </MuiThemeProvider>
  );
};

export default Routes;
