import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const MAX_SIZE = 3145728;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#F86115'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: 'red'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  justifyContent: 'center'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const mappedMessages = [
  {

  }
]

const DropzoneField = ({ name, placeholder, getFiles, deleteFile, fileList, loading }) => {
  const [preview, setPreview] = useState(null);

  function nameCheckValidator(file) {
    const fileExists = fileList.filter(f => f.file).find(f => file.name === f.file.name);

    if (fileExists) {
      return {
        code: 'name-already-exists',
        message: `La imagen ${file.name} ya ha sido agregada`
      };
    }

    return null;
  }
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'image/*': [] },
    maxSize: MAX_SIZE,
    multiple: false,
    validator: nameCheckValidator,
    onDrop: acceptedFiles => {
      getFiles(name, acceptedFiles[0]);
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    },
    onFileDialogCancel: () => deleteFile(name),
    disabled: loading
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = acceptedFiles.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(preview) }}
        />
      </div>
    </div>
  ));

  const getFormattedErrorMessage = (error, file) => {
    switch (error.code) {
      case 'file-too-large': {
        return `La imagen excede los ${MAX_SIZE} bytes`
      };
      default:
        return error.message;
    }
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <span key={file.path}>
      {errors.map(e => (
        <span style={{ color: 'red' }} key={e.code}>{getFormattedErrorMessage(e, file)}</span>
      ))}
    </span>
  ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ color: '#415364' }}>{placeholder}</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
        <ul>{fileRejectionItems}</ul>
      </aside>
    </div>
  )
}

export default DropzoneField;