import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Grid, InputAdornment, IconButton, Tooltip, FormHelperText, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, AttachMoney, Input } from '@material-ui/icons';
import { isValidEmail } from '../../../utils';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
    field: {
        margin: 16
    },
    input: {
        marginTop: '2rem !important'
    },
    label: {
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: 16,
        marginBottom: 18,
        '& svg': {
            marginRight: 6,
            fontSize: 16
        },
        color: theme.palette.typography.main,
        '& label': {
            overflow: 'unset',
            maxWidth: '100%',
            whiteSpace: 'break-spaces'
        }
    }
}));

const QuoteInputString = ({
    handleSelect,
    label,
    name,
    type,
    handleCheck,
    isLast,
    value,
    autocomplete,
    min,
    max,
    validations,
    wizard,
    isOptional,
    mask,
    formatByMask,
    quotationScreenOptions,
    handleNextButtonValid
}) => {
    const classes = useStyles();
    const [inputError, setInputError] = useState({ helperText: '', error: false });
    const config = useSelector(state => state.config.data);

    useEffect(() => {
        if (quotationScreenOptions.nextButtonType === 'ext') {
            handleNextButtonValid(isValid());
        }
    }, [value]);

    const changeValidEmailFormat = (e) => {
        if (!e.target.value) {
            setInputError({ helperText: 'El campo no puede estar vacio', error: true });
        } else if (!isValidEmail(e.target.value) && type === 'email') {
            setInputError({ helperText: 'Correo electrónico no válido', error: true });
        } else if (isValidEmail(e.target.value) && type === 'email') {
            setInputError({ helperText: '', error: false });
        } else {
            setInputError({ helperText: '', error: false });
        }
    };

    const changeValidNumericFormat = (value) => {
        if (min !== null && max !== null) {
            if (Number(value) < Number(min)) {
                setInputError({ helperText: `El valor debe ser mayor a ${mask && mask.toLowerCase() === 'moneda' ? `$${new Intl.NumberFormat('en-US').format(min)}` : min}`, error: true });
            } else if (Number(value) > Number(max)) {
                setInputError({ helperText: `El valor debe ser menor a ${mask && mask.toLowerCase() === 'moneda' ? `$${new Intl.NumberFormat('en-US').format(max)}` : max}`, error: true });
            } else {
                setInputError({ helperText: '', error: false });
            }
        }

        if (validations.pattern !== '') {
            const pattern = new RegExp(validations.pattern, 'gi');
            if (!pattern.test(value)) {
                setInputError({ helperText: validations.message, error: true });
            } else {
                setInputError({ helperText: '', error: false });
            }
        }
    }

    const changeValidInputFormat = (value) => {
        if (validations.pattern !== '') {
            const pattern = new RegExp(validations.pattern, 'gi');
            if (!pattern.test(value)) {
                setInputError({ helperText: validations.message, error: true });
            } else {
                setInputError({ helperText: '', error: false });
            }
        }
    };

    const isValid = () => {
        if (value === '' && isOptional) {
            return true;
        } else {
            let conditions = false;
            if (autocomplete) {
                conditions = value !== null;
            } else {
                conditions = value !== '';
            }

            if (type === 'email') {
                conditions = conditions && isValidEmail(value);
            }

            if (type === 'numeric' && min !== null && max !== null) {
                const numberWithoutFormat = value.includes(',') ? value.replaceAll(',', '') : value;
                conditions = Number(numberWithoutFormat) >= Number(min) && Number(numberWithoutFormat) <= Number(max);
            }

            if (type === 'numeric') {
                const numberWithoutFormat = value && value.includes(',') ? value.replaceAll(',', '') : value;
                conditions = !invalidDecimalFormat(numberWithoutFormat);
            }

            if (validations.pattern !== '') {
                const pattern = new RegExp(validations.pattern, 'gi');
                conditions = pattern.test(value);
            }

            return conditions;
        }

    };

    const invalidDecimalFormat = (val) => {
        const dotPosition = val ? val.indexOf('.') : 0;
        return dotPosition > 0 && dotPosition === val.length - 1;
    };

    const handleOnChange = (e) => {
        if (type === 'email') {
            changeValidEmailFormat(e);
        }

        if (type === 'numeric') {
            const numberWithoutFormat = e.target.value.includes(',') ? e.target.value.replaceAll(',', '') : e.target.value;
            const formattedValue = !invalidDecimalFormat(numberWithoutFormat) ? formatByMask(numberWithoutFormat) : numberWithoutFormat;
            if (/(^\d+$|,|\u002E)/g.test(formattedValue) || formattedValue === '') {
                changeValidNumericFormat(numberWithoutFormat);
                handleSelect(formattedValue);
            }
        } else {
            const formattedValue = formatByMask(e.target.value);
            changeValidInputFormat(formattedValue);
            handleSelect(formattedValue);
        }
    };

    const handleKeyUp = (e) => {
        if (isValid()) {
            if (e.key == 'Enter') {
                handleOnCheck();
            }
        }
    }

    const handleOnCheck = () => {
        handleCheck(value);
    };

    return (
        <Grid container>
            {(config.theme && !config.theme.labelPosition) && (
                <div className={classes.label}>
                    <ArrowForwardIos color="primary" />
                    <InputLabel id="external-input-label">{label}</InputLabel>
                </div>)}
            <TextField
                label={(config.theme && config.theme.labelPosition) ? label : null}
                placeholder=""
                type={type}
                className={classes.field}
                fullWidth
                error={inputError.error}
                helperText={inputError.helperText ? inputError.helperText : (isOptional ? 'En el caso de que no aplique, selecciona Siguiente'  : '')}
                value={value}
                onChange={handleOnChange}
                onKeyUp={handleKeyUp}
                InputProps={{
                    startAdornment: mask && mask.toLowerCase() === 'moneda' && (
                        <InputAdornment position="start" style={{ marginRight: 0 }}>
                            <AttachMoney color="primary" style={{ fontSize: 18 }} />
                        </InputAdornment>
                    ),
                    endAdornment: ((name !== 'lead' || wizard) && quotationScreenOptions.nextButtonType === 'int') && (
                        <InputAdornment position="end">
                            <Tooltip title={isLast ? 'Ingresar' : 'Siguiente'} arrow open={isValid()} placement="top">
                                <IconButton
                                    aria-label="check input value"
                                    onClick={handleOnCheck}
                                    disabled={!isValid()}
                                    style={{
                                        color: isValid() ? config.theme.primary.main : 'rgba(0, 0, 0, 0.26)'
                                    }}
                                >
                                    <Input />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ),
                    margin: 'dense'
                }}
            />
        </Grid>
    );
};

export default QuoteInputString;
