import { createAsyncAction } from 'redux-promise-middleware-actions';
import settings from '../settings';
import { validateResponseWithError, exeptionCodeResponse } from '../utils';
import { CREATE_DIRECT_EMISSION, CREATE_REQUEST_EMISSION } from '../actions/types.js';

export const createDirectEmissionWFAPI = createAsyncAction(
    CREATE_DIRECT_EMISSION,
    async (bodyReq) => {
        const headers = { 'Content-Type': 'application/json' };

        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.agentUrlPublicApi}createDirectEmission`, {
            method: 'POST',
            headers,
            body
        })
            .then((response) => {
                return validateResponseWithError(response);
            })
            .catch((error) => {
                if (error === 'desactualizado') {
                    alert(
                        'Alquién ha actualizado esta solicitud. Por favor, vuelve a cargar la misma para actualizar su estado.'
                    );
                }
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);

export const createRequestEmissionWFAPI = createAsyncAction(
    CREATE_REQUEST_EMISSION,
    async (bodyReq) => {
        const headers = { 'Content-Type': 'application/json' };

        const body = JSON.stringify(bodyReq);
        const res = await fetch(`${settings.agentUrlPublicApi}createDirectEmission`, {
            method: 'POST',
            headers,
            body
        })
            .then((response) => {
                return validateResponseWithError(response);
            })
            .catch((error) => {
                if (error === 'desactualizado') {
                    alert(
                        'Alquién ha actualizado esta solicitud. Por favor, vuelve a cargar la misma para actualizar su estado.'
                    );
                }
                throw exeptionCodeResponse(error);
            });
        return res;
    }
);
