import React, { useEffect, useState } from 'react';
import HomeInfoComponent from './homeInfoComponent';
import { AUTOS, GASTOS_MEDICOS, HOGAR, SALUD, VIDA } from '../../../utils/risksTypes';
import CarInfoComponent from './carInfoComponent';
import PropTypes from 'prop-types';
import DynamicForm from '../../../components/dynamicForm/indexRefactored';
import { getFieldsConfigurationByInsuranceId, getFieldsConfigurationById } from '../../../sevices/quoteService';
import LayoutLoading from '../../../components/layout/layoutLoading';
import Wizard from '../../../components/dynamicForm/wizard';
import { useSelector } from 'react-redux';

const ProductInfoComponent = ({ riskType, id, insuranceId, formBuilderId }) => {
    riskType = riskType && riskType.toUpperCase();
    const [data, setData] = useState(null);
    const insurances = useSelector(state => state.insurance.data);
    const [insurance, setInsurance] = useState();

    useEffect(() => {
        const insuranceResult = insurances.find(
            (insuranceFind) => insuranceFind._id === insuranceId
        );
        setInsurance(insuranceResult);
    }, [insurances]);

    useEffect(() => {
        if (id !== 'null') {
            if (insuranceId) {
                if (sessionStorage.getItem(`quotation::${riskType}`)) {
                    const _data = JSON.parse(sessionStorage.getItem(`quotation::${riskType}`));
                    setData(_data);
                } else {
                    getFieldsConfigurationByInsuranceId(insuranceId, id).then((data) => {
                        setData(data);
                    });
                }
            } else {
                getFieldsConfigurationById(id).then((data) => {
                    setData(data);
                });
            }
        }
    }, [id]);

    const getProductByTag = (tags) => {
        if (tags) {
            const comercialTag = tags.split(',').find(t => t.includes('ProductoComercial'));

            const productId = comercialTag.split('=')[1];
            const isMultiple = productId.includes('|');
            return isMultiple ? productId.split('|') : productId;
        }

        return null;
    };

    const getProductInfoComponent = (riskType, id) => {
        if (id !== 'null') {
            if (!data) {
                return <LayoutLoading />;
            }
            return (
                data.options && data.options.navigationType.includes('wizard') ?
                    <Wizard data={data} riskType={riskType} insurance={insuranceId} formBuilderId={formBuilderId} saved={sessionStorage.getItem(`quotation::${riskType}`)} /> :
                    <DynamicForm options={insurance && insurance.options} data={data} riskType={riskType} insurance={insuranceId} product={getProductByTag(insurance?.tags)} calculationType={insurance?.options?.calculationType} formBuilderId={formBuilderId} saved={sessionStorage.getItem(`quotation::${riskType}`)} />
            );
        } else {
            switch (riskType.toUpperCase()) {
                case AUTOS:
                    return (
                        <CarInfoComponent />
                    );
                case HOGAR:
                    return (
                        <HomeInfoComponent/>
                    );
                default:
                    return <LayoutLoading />;
            }
        }
    };

    return insurances.length > 0 && getProductInfoComponent(riskType, id);
};

export default ProductInfoComponent;
